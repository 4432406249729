import React from 'react'
import { useDispatch } from 'react-redux'
import _ from 'lodash'
import {
  Button,
  Row,
  Col,
  Form,
  InputGroup,
  ListGroup,
  Offcanvas
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  modifyArticle,
  createFigure
} from './articleAPI'
import {
  ArticleFigureForm,
  ArticleAreaForm,
  ArticleHeadingsForm,
  ArticlePreambleForm,
  ArticleTemplateArticlesForm,
  TagEditor,
  normalHeadingTypes,
  getSubmitParamForArticleUpsert,
  isSizedArticleType,
  isPositionRequired,
  stripTags,
  getNumberOfRows,
  getParagraphStyleName,
  changeParagraphStyleMultiline
} from '../article'
import {
  CustomForm,
  FigureType
} from '../common'
import { shouldShowExperimentalFeatures } from '../auth'
import { selectCell } from './articleSlice'
import * as Yup from 'yup'
import * as ja from 'yup-locale-ja'
Yup.setLocale(ja.suggestive)

const ArticleHeadingsDetail = ({
  article,
  layoutableType,
  layoutableId,
  layoutable,
  enumValues,
  handleChangeArticleSubmit,
  ...props
}) => {
  if (!article?.headings?.length) {
    return <>見出しなし</>
  }
  const basicNormalHeadings = article?.headings?.filter(h => normalHeadingTypes.has(h.headingType))
  const firstNormalheading = basicNormalHeadings?.[0]
  const { headingNumberOfOccupyingColumns, headingNumberOfOccupyingRows } = article
  const form = {
    initialValues: {
      article,
      layoutableType,
      layoutableId,
      layoutableLockVersion: layoutable?.lockVersion,
      headingNumberOfOccupyingColumns,
      headingNumberOfOccupyingRows,
      headingObjectStyleType: firstNormalheading?.headingObjectStyleType || '',
      keepOpen: true
    },
    handleSubmit: handleChangeArticleSubmit,
    validationSchema: ArticleHeadingsForm.schema,
    Component: ArticleHeadingsForm,
    props: {
      ...props,
      layoutableType,
      layoutableId,
      layoutable,
      article,
      enumValues
    }
  }
  return (
    <CustomForm form={form} />
  )
}

const ArticleFigure = ({
  article,
  layoutableType,
  layoutableId,
  figure,
  enumValues,
  ...props
}) => {
  const form = {
    initialValues: {
      ...figure,
      articleId: article.id
    },
    validationSchema: ArticleFigureForm.schema,
    Component: ArticleFigureForm,
    props: {
      ...props,
      ...{
        figureId: figure.id,
        articleId: article.id,
        layoutableType,
        layoutableId,
        enumValues,
        article
      }
    }
  }
  return <CustomForm form={form} />
}

const AddFigureForm = ({
  article,
  layoutableId,
  layoutableType,
  isSelectedLayoutableWritable
  // ...props
}) => {
  const emptyFigure = {
    articleId: article.id,
    figureType: FigureType.Photo,
    caption: '',
    taggedCaption: ''
  }
  const dispatch = useDispatch()
  return (
    <Button
      icon='plus'
      variant='primary'
      size='sm'
      disabled={!isSelectedLayoutableWritable || article.layoutLocked}
      onClick={(e) => {
        e.preventDefault()
        dispatch(createFigure({ layoutableType, layoutableId, figure: emptyFigure }))
        dispatch(selectCell(null))
      }}
    >
      <FontAwesomeIcon icon='plus' fixedWidth />追加
    </Button>
  )
}

const HalfSizeImageCharacterSettingForm = ({
  article,
  layoutable,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  isSelectedLayoutableWritable,
  isValid
}) => {
  const isDisabled = !isSelectedLayoutableWritable || article.layoutLocked
  return (
    <Form noValidate onSubmit={handleSubmit}>
      <Row className='mb-2'>
        <Col>
          <div className='fs-5'>画像半指定時の字数設定（紙面字数: {values.numberOfCharactersPerRow}字）</div>
        </Col>
      </Row>
      <Row className='mb-2'>
        <Form.Group as={Col}>
          <InputGroup size='sm' hasValidation>
            <InputGroup.Text>画像</InputGroup.Text>
            <Form.Control
              type='number'
              name='imageCharacters'
              value={values?.imageCharacters || ''}
              onBlur={handleBlur}
              onChange={handleChange}
              isInvalid={touched?.imageCharacters && errors?.imageCharacters}
              disabled={isDisabled}
            />
            <InputGroup.Text>字</InputGroup.Text>
            <Form.Control.Feedback type='invalid'>
              {errors?.imageCharacters}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        <Form.Group as={Col}>
          <InputGroup size='sm' hasValidation>
            <InputGroup.Text>空白</InputGroup.Text>
            <Form.Control
              type='number'
              name='spaceCharacters'
              value={values?.spaceCharacters || ''}
              readOnly
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched?.spaceCharacters && errors?.spaceCharacters}
              disabled={isDisabled}
            />
            <InputGroup.Text>字</InputGroup.Text>
            <Form.Control.Feedback type='invalid'>
              {errors?.spaceCharacters}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        <Form.Group as={Col}>
          <InputGroup size='sm' hasValidation>
            <InputGroup.Text>記事</InputGroup.Text>
            <Form.Control
              type='number'
              name='textCharacters'
              value={values?.textCharacters || ''}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched?.textCharacters && errors?.textCharacters}
              disabled={isDisabled}
            />
            <InputGroup.Text>字</InputGroup.Text>
            <Form.Control.Feedback type='invalid'>
              {errors?.textCharacters}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
      </Row>
      <br />
      <Row>
        <Col>
          <Button type='submit' disabled={!isValid || _.isEmpty(touched)} size='sm'>更新</Button>
        </Col>
      </Row>
    </Form>
  )
}
const validateHalfSizeImageCharacterSettings = (_, context) => {
  const {
    numberOfCharactersPerRow, imageCharacters, spaceCharacters, textCharacters
  } = context.parent
  return (imageCharacters + spaceCharacters + textCharacters) === numberOfCharactersPerRow
}
const characterCountValidationMessage = '「画像」「空白」「記事」の合計は紙面の「字数」と一致する必要があります。'

HalfSizeImageCharacterSettingForm.schema = Yup.object().shape({
  numberOfCharactersPerRow: Yup.number().required(),
  imageCharacters: Yup.number().required().min(1).test(
    'character_count_validation',
    characterCountValidationMessage,
    validateHalfSizeImageCharacterSettings
  ),
  spaceCharacters: Yup.number().required().min(1).test(
    'character_count_validation',
    characterCountValidationMessage,
    validateHalfSizeImageCharacterSettings
  ),
  textCharacters: Yup.number().required().min(1).test(
    'character_count_validation',
    characterCountValidationMessage,
    validateHalfSizeImageCharacterSettings
  )
})

const ArticleFiguresDetail = ({
  article,
  handleChangeArticleSubmit,
  ...props
}) => {
  if (!article?.figures?.length) {
    return (
      <>
        <Row><Col>写真・図なし</Col></Row>
        <AddFigureForm article={article} {...props} />
      </>
    )
  }
  const {
    layoutableType,
    layoutableId,
    layoutable
  } = props
  const columnsSettings = article?.figures?.map(f => f.numberOfOccupyingColumns)
  const containsHalfSizeSettings = columnsSettings?.some(s => s?.endsWith('.5'))
  // console.log({columnsSettings, containsHalfSizeSettings})
  const halfSizeImageCharacterSetting = article?.halfSizeImageCharacterArticleSetting ?? layoutable.setting.halfSizeImageCharacterSetting
  const { id, articleId, ...noIdHalfSizeImageCharacterSetting } = halfSizeImageCharacterSetting
  // console.log({columnsSettings, containsHalfSizeSettings})
  // console.log({ halfSizeImageCharacterSetting, article: article?.halfSizeImageCharacterArticleSetting, setting: props.layoutable.setting.halfSizeImageCharacterSetting })
  const handleSubmit = (values) => {
    const {
      id,
      numberOfCharactersPerRow,
      imageCharacters,
      spaceCharacters,
      textCharacters,
      article,
      ...restValues
    } = values
    const newArticle = {
      ...article,
      halfSizeImageCharacterArticleSetting: {
        imageCharacters,
        spaceCharacters,
        textCharacters
      }
    }
    // console.log({ values, id, newArticle, restValues })
    handleChangeArticleSubmit({ article: newArticle, ...restValues })
  }
  const halfSizeSettingsForm = {
    initialValues: {
      article,
      layoutableType,
      layoutableId,
      layoutableLockVersion: layoutable?.lockVersion,
      numberOfCharactersPerRow: layoutable.numberOfCharactersPerRow,
      ...noIdHalfSizeImageCharacterSetting
    },
    validationSchema: HalfSizeImageCharacterSettingForm.schema,
    handleSubmit,
    Component: HalfSizeImageCharacterSettingForm,
    props: {
      ...props,
      ...{
        article,
        layoutable
      }
    }
  }
  return (
    <>
      {containsHalfSizeSettings &&
        <>
          <CustomForm form={halfSizeSettingsForm} />
          <hr />
        </>}
      <ListGroup>
        {article.figures.map(figure =>
          <ArticleFigure key={figure.id} article={article} figure={figure} {...props} />
        )}
      </ListGroup>
      <AddFigureForm article={article} {...props} />
    </>
  )
}

const ArticleArea = ({
  article,
  layoutableType,
  layoutableId,
  area,
  enumValues,
  ...props
}) => {
  const form = {
    initialValues: {
      ...area,
      isPositionRequired: isPositionRequired(article.articleType)
    },
    validationSchema: ArticleAreaForm.schema,
    Component: ArticleAreaForm,
    props: {
      ...props,
      ...{
        areaId: area.id,
        layoutableType,
        layoutableId,
        enumValues,
        article
      }
    }
  }
  return <CustomForm form={form} />
}

const ArticleAreasDetail = ({
  article,
  ...props
}) => {
  if (isSizedArticleType(article.articleType) || !article?.areas?.length) {
    return <>領域なし</>
  }
  return (
    <ListGroup>
      {article.areas.map(area =>
        <ArticleArea key={area.id} article={article} area={area} {...props} />
      )}
    </ListGroup>
  )
}

const detailPanePreStyle = {
  overflow: 'auto',
  wordWrap: 'normal',
  whiteSpace: 'pre-wrap'
}

const ArticleBodyDetail = ({
  article,
  layoutableType,
  layoutableId,
  layoutable,
  isSelectedLayoutableWritable
  // ...props
}) => {
  if (!article.bodyContent) {
    return <>本文なし</>
  }
  const dispatch = useDispatch()
  const paragraphStyleName = getParagraphStyleName(layoutable.setting, 'body')

  const onSubmit = (taggedContent) => {
    const bodyContent = stripTags(taggedContent)
    const numberOfCharactersPerRow = layoutable?.setting?.columnSetting?.numberOfCharactersPerRow
    const bodyNumberOfRows = !bodyContent ? null : getNumberOfRows(numberOfCharactersPerRow, taggedContent, true)
    const bodyTaggedContent = changeParagraphStyleMultiline(taggedContent, paragraphStyleName)
    const newArticle = { ...article, bodyContent, bodyTaggedContent, bodyNumberOfRows }
    const props = { layoutableType, layoutableId, layoutableLockVersion: layoutable?.lockVersion, article: newArticle }
    // console.log(newArticle)
    const submitParam = getSubmitParamForArticleUpsert(props)
    dispatch(modifyArticle(submitParam))
    dispatch(selectCell(null))
  }
  const inputDisabled = !isSelectedLayoutableWritable || article.layoutLocked
  const editable = !inputDisabled && shouldShowExperimentalFeatures()
  return (
    <>
      <Row className='mb-2'>
        <Col>
          <div className='bodyNumberOfRows fs-5'>本文行数: {article.bodyNumberOfRows}</div>
        </Col>
      </Row>
      <Row>
        <Col>
          <TagEditor
            initialTaggedContent={article.bodyTaggedContent}
            styleTagType='sentence'
            setting={layoutable.setting}
            onSubmit={onSubmit}
            disabled={inputDisabled}
            editable={editable}
            allowLineBreak
            allowMultiParagraph
          />
        </Col>
      </Row>
    </>
  )
}

const ArticlePreambleDetail = ({
  layoutableType,
  layoutableId,
  layoutable,
  article,
  handleChangeArticleSubmit,
  ...props
}) => {
  const { preambleNumberOfColumns, preambleNumberOfRows, preambleNumberOfCharactersPerRow, preambleMarginRows } = article
  const form = {
    initialValues: {
      layoutableType,
      layoutableId,
      layoutableLockVersion: layoutable?.lockVersion,
      article,
      preambleNumberOfColumns,
      preambleNumberOfRows,
      preambleNumberOfCharactersPerRow,
      preambleMarginRows
    },
    handleSubmit: handleChangeArticleSubmit,
    validationSchema: ArticlePreambleForm.schema,
    Component: ArticlePreambleForm,
    props: {
      ...props,
      layoutableType,
      layoutableId,
      layoutable,
      detailPanePreStyle
    }
  }
  return (
    <CustomForm form={form} />
  )
}

const ArticleTemplateArticlesDetail = ({
  article,
  layoutableType,
  layoutableId,
  ...props
}) => {
  const form = {
    initialValues: {
      layoutableType,
      layoutableId,
      article
    },
    // handleSubmit: handleChangeArticleSubmit,
    validationSchema: ArticleTemplateArticlesForm.schema,
    Component: ArticleTemplateArticlesForm,
    props: {
      ...props,
      article,
      layoutableType,
      layoutableId
    }
  }
  return (
    <CustomForm form={form} />
  )
}

const ArticleDetailBody = ({
  articles,
  cell,
  ...props
}) => {
  if (!articles || !cell) {
    return (<>Click Cell to see details</>)
  }
  const article = articles?.at(cell.rowId)
  switch (cell.column) {
    case 'headings':
      return (<ArticleHeadingsDetail {...props} {...{ article }} />)
    case 'figures':
      return (<ArticleFiguresDetail {...props} {...{ article }} />)
    case 'areas':
      return (<ArticleAreasDetail {...props} {...{ article }} />)
    case 'bodyColumn':
      return (<ArticleBodyDetail {...props} {...{ article }} />)
    case 'preambleColumn':
      return (<ArticlePreambleDetail {...props} {...{ article }} />)
    case 'templateArticle':
      return (<ArticleTemplateArticlesDetail {...props} {...{ article }} />)
    default:
      return (<>unknown</>)
  }
}

const getDetailTitle = (column) => {
  switch (column) {
    case 'headings':
      return '見出し'
    case 'figures':
      return '写真・図'
    case 'areas':
      return '領域'
    case 'bodyColumn':
      return '本文'
    case 'preambleColumn':
      return '前文'
    case 'templateArticle':
      return '在版'
    default:
      return null
  }
}

export const ArticleDetail = ({
  cell,
  unselectCell,
  ...props
}) => {
  if (!cell) return
  const detailTitle = getDetailTitle(cell.column)
  if (!detailTitle) return
  return (
    <Offcanvas show={cell !== null} onHide={unselectCell} placement='end' backdrop='static' style={{ width: '600px' }}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>{detailTitle}</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <ArticleDetailBody cell={cell} {...props} {...{ unselectCell }} />
      </Offcanvas.Body>
    </Offcanvas>
  )
}
