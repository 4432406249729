import React from 'react'
import _, { camelCase } from 'lodash'
import {
  Form,
  InputGroup,
  ListGroup,
  Row,
  Col,
  Button,
  Modal,
  Alert
} from 'react-bootstrap'
import {
  getColumnString
} from '../common'
import * as Yup from 'yup'
import * as ja from 'yup-locale-ja'
Yup.setLocale(ja.suggestive)

export const ColumnSettingForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  isValid,
  enumValues,
  isSettingWritable
}) => {
  return (
    <Form
      noValidate
      onSubmit={handleSubmit}
    >
      <Row className='mb-3'>
        <Form.Group as={Col}>
          <Form.Label column>組方向</Form.Label>
          <Col>
            <Form.Control
              as='select'
              type='text'
              name='orientation'
              value={values?.orientation || ''}
              onBlur={handleBlur}
              onChange={handleChange}
              isInvalid={touched.orientation && errors.orientation}
              disabled={!isSettingWritable}
            >
              {Object.keys(enumValues.columnSettingOrientations).map(k => (<option key={k} value={k}>{enumValues.columnSettingOrientations[k]}</option>))}
            </Form.Control>
            <Form.Control.Feedback type='invalid'>
              {errors.orientation}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Label column>段組体裁</Form.Label>
          <Col>
            <Form.Control
              as='select'
              type='text'
              name='typeOf'
              value={values.typeOf || ''}
              onBlur={handleBlur}
              onChange={handleChange}
              isInvalid={touched.typeOf && errors.typeOf}
              disabled={!isSettingWritable}
            >
              {Object.keys(enumValues.columnSettings).map(k => (<option key={k} value={k}>{enumValues.columnSettings[k]}</option>))}
            </Form.Control>
            <Form.Control.Feedback type='invalid'>
              {errors.typeOf}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
      </Row>
      <Row className='mb-3'>
        <Form.Group as={Col}>
          <Form.Label column>字数</Form.Label>
          <Col>
            <Form.Control
              type='number'
              name='numberOfCharactersPerRow'
              value={values?.numberOfCharactersPerRow || ''}
              onBlur={handleBlur}
              onChange={handleChange}
              isInvalid={touched?.numberOfCharactersPerRow && errors?.numberOfCharactersPerRow}
              disabled={!isSettingWritable || values?.typeOf === 'regular'}
            />
            <Form.Control.Feedback type='invalid'>
              {errors?.numberOfCharactersPerRow}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Label column>行数</Form.Label>
          <Col>
            <Form.Control
              type='number'
              name='numberOfRows'
              value={values?.numberOfRows || ''}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched?.numberOfRows && errors?.numberOfRows}
              disabled={!isSettingWritable || values?.typeOf === 'regular'}
            />
            <Form.Control.Feedback type='invalid'>
              {errors?.numberOfRows}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Label column>段数</Form.Label>
          <Col>
            <Form.Control
              type='number'
              name='numberOfColumns'
              value={values?.numberOfColumns || ''}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched?.numberOfColumns && errors?.numberOfColumns}
              disabled={!isSettingWritable || values?.typeOf === 'regular'}
            />
            <Form.Control.Feedback type='invalid'>
              {errors?.numberOfColumns}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
      </Row>
      <br />
      <Row>
        <Col>
          <Button type='submit' disabled={!isSettingWritable || !isValid || _.isEmpty(touched)} size='sm'>更新</Button>
        </Col>
      </Row>
    </Form>
  )
}
ColumnSettingForm.schema = Yup.object().shape({
  orientation: Yup.string().required(),
  typeOf: Yup.string().required(),
  numberOfCharactersPerRow: Yup.number().required().min(10).max(30),
  numberOfRows: Yup.number().required().min(30).max(100),
  numberOfColumns: Yup.number().required().min(7).max(15)
})

export const MarginOutputSettingForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  isValid,
  isSettingWritable
}) => {
  return (
    <Form
      noValidate
      onSubmit={handleSubmit}
    >
      <Form.Check
        type='switch'
        id='fullWidthYearSwitch'
        label='年・全角'
        name='fullWidthYear'
        value={values.fullWidthYear}
        checked={values.fullWidthYear}
        onChange={handleChange}
        onBlur={handleBlur}
        isInvalid={touched.fullWidthYear && errors.fullWidthYear}
        disabled={!isSettingWritable}
      />
      <Form.Check
        type='switch'
        id='fullWidthMonthSwitch'
        label='月・全角'
        name='fullWidthMonth'
        value={values.fullWidthMonth}
        checked={values.fullWidthMonth}
        onChange={handleChange}
        onBlur={handleBlur}
        isInvalid={touched.fullWidthMonth && errors.fullWidthMonth}
        disabled={!isSettingWritable}
      />
      <Form.Check
        type='switch'
        id='fullWidthDaySwitch'
        label='日・全角'
        name='fullWidthDay'
        value={values.fullWidthDay}
        checked={values.fullWidthDay}
        onChange={handleChange}
        onBlur={handleBlur}
        isInvalid={touched.fullWidthDay && errors.fullWidthDay}
        disabled={!isSettingWritable}
      />
      <Form.Check
        type='switch'
        id='fullWidthWarekiSwitch'
        label='和暦・全角'
        name='fullWidthWareki'
        value={values.fullWidthWareki}
        checked={values.fullWidthWareki}
        onChange={handleChange}
        onBlur={handleBlur}
        isInvalid={touched.fullWidthWareki && errors.fullWidthWareki}
        disabled={!isSettingWritable}
      />
      <Form.Check
        type='switch'
        id='fullWidthIssueSwitch'
        label='号・全角'
        name='fullWidthIssue'
        value={values.fullWidthIssue}
        checked={values.fullWidthIssue}
        onChange={handleChange}
        onBlur={handleBlur}
        isInvalid={touched.fullWidthIssue && errors.fullWidthIssue}
        disabled={!isSettingWritable}
      />
      <Form.Check
        type='switch'
        id='fullWidthRevisionSwitch'
        label='版・全角'
        name='fullWidthRevision'
        value={values.fullWidthRevision}
        checked={values.fullWidthRevision}
        onChange={handleChange}
        onBlur={handleBlur}
        isInvalid={touched.fullWidthRevision && errors.fullWidthRevision}
        disabled={!isSettingWritable}
      />
      <Form.Check
        type='switch'
        id='fullWidthOtherSwitch'
        label='他・全角'
        name='fullWidthOther'
        value={values.fullWidthOther}
        checked={values.fullWidthOther}
        onChange={handleChange}
        onBlur={handleBlur}
        isInvalid={touched.fullWidthOther && errors.fullWidthOther}
        disabled={!isSettingWritable}
      />
      <br />
      <Row>
        <Col>
          <Button type='submit' disabled={!isSettingWritable || !isValid || _.isEmpty(touched)} size='sm'>更新</Button>
        </Col>
      </Row>
    </Form>
  )
}
MarginOutputSettingForm.schema = Yup.object().shape({
  fullWidthYear: Yup.boolean().required(),
  fullWidthMonth: Yup.boolean().required(),
  fullWidthDay: Yup.boolean().required(),
  fullWidthWareki: Yup.boolean().required(),
  fullWidthIssue: Yup.boolean().required(),
  fullWidthRevision: Yup.boolean().required(),
  fullWidthOther: Yup.boolean().required()
})

export const VerticalHeadingMarginSettingForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  isSettingWritable,
  rowIndex
}) => {
  return (
    <Row sm='auto' className={`row-${rowIndex + 1}`}>
      <Col sm={2}>
        {values.columns}段{values.headings}本見出し
      </Col>
      <Col>
        <InputGroup size='sm' hasValidation>
          <Form.Control
            id='rowsInput'
            type='number'
            name='rows'
            value={values.rows || ''}
            onChange={(e) => {
              handleChange(e)
              handleSubmit(values)
            }}
            onBlur={handleBlur}
            isInvalid={touched.rows && errors.rows}
            disabled={!isSettingWritable}
          />
          <InputGroup.Text>行取り</InputGroup.Text>
          <Form.Control.Feedback type='invalid'>
            {errors.rows}
          </Form.Control.Feedback>
        </InputGroup>
      </Col>
    </Row>
  )
}
VerticalHeadingMarginSettingForm.schema = Yup.object().shape({
  rows: Yup.number().min(1).required()
})

export const CoveringMarginSettingForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  isSettingWritable,
  rowIndex
}) => {
  return (
    <Row sm='auto' className={`row-${rowIndex + 1}`}>
      <Col sm={2}>
        {values.columns}段被せ
      </Col>
      <Col>
        <InputGroup size='sm' hasValidation>
          <Form.Control
            id='charactersInput'
            type='number'
            name='characters'
            value={values.characters || ''}
            onChange={(e) => {
              handleChange(e)
              handleSubmit(values)
            }}
            onBlur={handleBlur}
            isInvalid={touched.characters && errors.characters}
            disabled={!isSettingWritable}
          />
          <InputGroup.Text>字取り</InputGroup.Text>
          <Form.Control.Feedback type='invalid'>
            {errors.characters}
          </Form.Control.Feedback>
        </InputGroup>
      </Col>
    </Row>
  )
}
CoveringMarginSettingForm.schema = Yup.object().shape({
  characters: Yup.number().min(1).required()
})

export const HorizontalHeadingMarginSettingForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  isSettingWritable,
  rowIndex
}) => {
  return (
    <Row sm='auto' className={`row-${rowIndex + 1}`}>
      <Col sm={3}>
        {values.columns}段横見出し（{values.sizeInColumns}段相当）
      </Col>
      <Col>
        <InputGroup size='sm' hasValidation>
          <Form.Control
            id='rowsInput'
            type='number'
            name='rows'
            value={values.rows || ''}
            onChange={(e) => {
              handleChange(e)
              handleSubmit(values)
            }}
            onBlur={handleBlur}
            isInvalid={touched.rows && errors.rows}
            disabled={!isSettingWritable}
          />
          <InputGroup.Text>行取り</InputGroup.Text>
          <Form.Control.Feedback type='invalid'>
            {errors.rows}
          </Form.Control.Feedback>
        </InputGroup>
      </Col>
    </Row>
  )
}
HorizontalHeadingMarginSettingForm.schema = Yup.object().shape({
  rows: Yup.number().min(1).required()
})

export const ParagraphStyleSettingForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleSubmit,
  enumValues,
  isSettingWritable,
  rowIndex
}) => {
  if (!values) {
    return (<></>)
  }
  const columnString = values?.columns === 0 ? '' : `${values?.columns}段`
  const typeString = enumValues.paragraphStyleSettingTypes[camelCase(values?.paragraphStyleSettingType)]
  const combinedColumnString = `${columnString}${typeString}`
  return (
    <Row sm='auto' className={`row-${rowIndex + 1}`}>
      <Col sm={3}>
        {combinedColumnString}
      </Col>
      <Col>
        <InputGroup size='sm' hasValidation>
          <Form.Control
            id={`nameInput-${values.id}`}
            type='text'
            name='name'
            value={values.name || ''}
            onBlur={(_) => handleSubmit(values)}
            onChange={handleChange}
            isInvalid={touched.name && errors.name}
            disabled={!isSettingWritable}
          />
          <Form.Control.Feedback type='invalid'>
            {errors.name}
          </Form.Control.Feedback>
        </InputGroup>
      </Col>
    </Row>
  )
}
ParagraphStyleSettingForm.schema = Yup.object().shape({
  name: Yup.string().trim().required()
})

export const HeadingObjectStyleSettingForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleSubmit,
  enumValues,
  isSettingWritable,
  rowIndex
}) => {
  if (!values) {
    return (<></>)
  }
  const columnString = values?.columns === 0 ? '' : `${values?.columns}段`
  const typeString = enumValues.headingObjectStyleSettingTypes[camelCase(values?.headingObjectStyleSettingType)]
  const combinedColumnString = `${columnString}${typeString}`
  return (
    <Row sm='auto' className={`row-${rowIndex + 1}`}>
      <Col sm={3}>
        {combinedColumnString}
      </Col>
      <Col sm={3}>
        <InputGroup size='sm' hasValidation>
          <Form.Control
            id={`nameInput-${values.id}`}
            type='text'
            name='name'
            value={values.name || ''}
            disabled={!isSettingWritable}
            onChange={handleChange}
            onBlur={(_) => handleSubmit(values)}
            isInvalid={touched.name && errors.name}
          />
          <Form.Control.Feedback type='invalid'>
            {errors.name}
          </Form.Control.Feedback>
        </InputGroup>
      </Col>
    </Row>
  )
}
HeadingObjectStyleSettingForm.schema = Yup.object().shape({
  name: Yup.string().trim().required()
})

export const CharacterStyleSettingForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleSubmit,
  enumValues,
  isSettingWritable,
  selectedIds,
  dispatch,
  selectId,
  unselectId,
  rowIndex
}) => {
  if (!values) {
    return (<></>)
  }
  const typeString = enumValues.characterStyleSettingTypes[camelCase(values?.characterStyleSettingType)]
  const combinedColumnString = `${values.description}(${typeString})`
  return (
    <Row sm='auto' className={`row-${rowIndex + 1}`}>
      <Col sm={3}>
        {combinedColumnString}
      </Col>
      <Col>
        <InputGroup size='sm' hasValidation>
          <Form.Control
            id={`nameInput-${values.id}`}
            type='text'
            name='name'
            value={values.name || ''}
            disabled={!isSettingWritable}
            onChange={handleChange}
            onBlur={(_) => handleSubmit(values)}
            isInvalid={touched.name && errors.name}
          />
          <Form.Control.Feedback type='invalid'>
            {errors.name}
          </Form.Control.Feedback>
        </InputGroup>
      </Col>
      <Col sm='auto'>
        <Form.Check.Input
          type='checkbox'
          name='checkedGroups'
          value={values.id}
          checked={selectedIds?.includes(parseInt(values.id))}
          onChange={e => dispatch((e.target.checked ? selectId : unselectId)(parseInt(e.target.value)))}
        />
      </Col>
    </Row>
  )
}
CharacterStyleSettingForm.schema = Yup.object().shape({
  name: Yup.string().trim().required()
})

export const CharacterStyleSettingDeleteForm = ({
  handleSubmit,
  title,
  submitButtonText,
  onClickCancel,
  selectedIds,
  characterStyleSettings,
  enumValues
}) => {
  const selectedIdSet = new Set(selectedIds)
  const deleteStyles = characterStyleSettings?.filter(s => selectedIdSet.has(s.id))
  // console.log('deleteStyles', { deleteStyles })
  return (
    <>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Form noValidate onSubmit={handleSubmit}>
        <Modal.Body>
          <p>以下の文字スタイルを削除します。よろしいですか？</p>
          <ListGroup>
            {deleteStyles.map(style => (
              <ListGroup.Item key={style.id}>
                id: {style.id},
                種別: {enumValues.characterStyleSettingTypes[camelCase(style.characterStyleSettingType)]},
                名称: {style.name}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={onClickCancel}>
            キャンセル
          </Button>
          <Button type='submit'>{submitButtonText}</Button>
        </Modal.Footer>
      </Form>
    </>
  )
}

export const CharacterStyleSettingUpsertForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  isValid,
  fetching,
  isSettingWritable,
  title,
  onClickCancel,
  submitButtonText,
  enumValues,
  alert
}) => (
  <>
    <Modal.Header>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    {alert?.show && alert?.alertBody &&
      <Alert
        className='upsert-char-style-setting-errors-alert'
        variant='danger'
      >
        {alert.alertBody}
      </Alert>}
    <Form onSubmit={handleSubmit}>
      <Modal.Body>
        <Form.Group as={Row} className='mb-3'>
          <Form.Label column className='col-3'>文字スタイル種別</Form.Label>
          <Col>
            <Form.Control
              as='select'
              type='text'
              name='characterStyleSettingType'
              value={values.characterStyleSettingType || ''}
              onBlur={handleBlur}
              onChange={handleChange}
              isInvalid={touched.characterStyleSettingType && errors.characterStyleSettingType}
              disabled={!isSettingWritable}
            >
              {Object.keys(enumValues.characterStyleSettingTypes).map(k => (<option key={k} value={k}>{enumValues.characterStyleSettingTypes[k]}</option>))}
            </Form.Control>
            <Form.Control.Feedback type='invalid'>
              {errors.characterStyleSettingType}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className='mb-3'>
          <Form.Label column className='col-3'>文字スタイルの説明</Form.Label>
          <Col>
            <Form.Control
              type='text'
              name='description'
              value={values.description}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched.description && errors.description}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.description}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className='mb-3'>
          <Form.Label column className='col-3'>文字スタイルの名称</Form.Label>
          <Col>
            <Form.Control
              type='text'
              name='name'
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched.name && errors.name}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.name}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={onClickCancel}>
          キャンセル
        </Button>
        <Button type='submit' disabled={!isValid || fetching}>{submitButtonText}</Button>
      </Modal.Footer>
    </Form>
  </>
)
CharacterStyleSettingUpsertForm.schema = Yup.object().shape({
  characterStyleSettingType: Yup.string().trim().required(),
  description: Yup.string().trim().required(),
  name: Yup.string().trim().required()
})

export const PageMarginSettingForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleSubmit,
  enumValues,
  isSettingWritable,
  selectedIds,
  dispatch,
  selectId,
  unselectId,
  setting,
  rowIndex
}) => {
  if (!values) {
    return (<></>)
  }
  const typeString = enumValues.pageMarginSettingTypes[camelCase(values?.pageMarginSettingType)]
  return (
    <Row sm='auto' className={`row-${rowIndex + 1}`}>
      <Col sm={2}>
        {typeString}
      </Col>
      <Col sm={2}>
        {values?.pageMarginSettingType === 'other'
          ? (setting?.pageMarginOtherSettings?.find(s => s.id === values?.pageMarginOtherSettingId)?.name || '')
          : '-'}
      </Col>
      <Col>
        <InputGroup size='sm' hasValidation>
          <Form.Control
            id={`nameInput-${values.id}`}
            type='text'
            name='name'
            value={values.name || ''}
            disabled={!isSettingWritable}
            onChange={handleChange}
            onBlur={(_) => handleSubmit(values)}
            isInvalid={touched.name && errors.name}
          />
          <Form.Control.Feedback type='invalid'>
            {errors.name}
          </Form.Control.Feedback>
        </InputGroup>
      </Col>
      <Col sm='auto'>
        <Form.Check.Input
          type='checkbox'
          name='checkedGroups'
          value={values.id}
          checked={selectedIds?.includes(parseInt(values.id))}
          onChange={e => dispatch((e.target.checked ? selectId : unselectId)(parseInt(e.target.value)))}
        />
      </Col>
    </Row>
  )
}
PageMarginSettingForm.schema = Yup.object().shape({
  name: Yup.string().trim().required(),
  pageMarginOtherSettingId: Yup.number().nullable(true)
})

export const PageMarginSettingUpsertForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  setFieldValue,
  isValid,
  fetching,
  enumValues,
  isSettingWritable,
  title,
  onClickCancel,
  submitButtonText,
  alert,
  largestOrders,
  setting
}) => {
  return (
    <>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      {alert?.show && alert?.alertBody &&
        <Alert
          className='upsert-char-style-setting-errors-alert'
          variant='danger'
        >
          {alert.alertBody}
        </Alert>}
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Form.Group as={Row} className='mb-3'>
            <Form.Label column className='col-3'>欄外項目種別</Form.Label>
            <Col>
              <Form.Control
                as='select'
                type='text'
                name='pageMarginSettingType'
                value={values.pageMarginSettingType || ''}
                onBlur={handleBlur}
                onChange={(e) => {
                  handleChange(e)
                  const pageMarginSettingType = e.target.value
                  const order = largestOrders[pageMarginSettingType] + 1
                  setFieldValue('order', order)
                  console.log('a', setting?.pageMarginOtherSettings?.length)
                  const pageMarginOtherSettingId = (pageMarginSettingType === 'other' && setting?.pageMarginOtherSettings?.length > 0)
                    ? setting?.pageMarginOtherSettings?.at(0)?.id ?? ''
                    : ''
                  setFieldValue('pageMarginOtherSettingId', pageMarginOtherSettingId)
                }}
                isInvalid={touched.pageMarginSettingType && errors.pageMarginSettingType}
                disabled={!isSettingWritable}
              >
                {Object.keys(enumValues.pageMarginSettingTypes).map(k => (
                  <option key={k} value={k} disabled={k === 'other' && setting?.pageMarginOtherSettings?.length === 0}>{enumValues.pageMarginSettingTypes[k]}</option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type='invalid'>
                {errors.pageMarginSettingType}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          {values.pageMarginSettingType === 'other' &&
            <Form.Group as={Row} className='mb-3'>
              <Form.Label column className='col-3'>その他項目名</Form.Label>
              <Col>
                <Form.Control
                  as='select'
                  type='text'
                  name='pageMarginOtherSettingId'
                  value={values.pageMarginOtherSettingId ?? ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.pageMarginOtherSettingId && errors.pageMarginOtherSettingId}
                  disabled={!isSettingWritable}
                >
                  {setting?.pageMarginOtherSettings?.map(s => <option key={s.name} value={s.id}>{s.name}</option>)}
                </Form.Control>
                <Form.Control.Feedback type='invalid'>
                  {errors.pageMarginOtherSettingId}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>}
          <Form.Group as={Row} className='mb-3'>
            <Form.Label column className='col-3'>欄外項目の名称</Form.Label>
            <Col>
              <Form.Control
                type='text'
                name='name'
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.name && errors.name}
              />
              <Form.Control.Feedback type='invalid'>
                {errors.name}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={onClickCancel}>
            キャンセル
          </Button>
          <Button type='submit' disabled={!isValid || fetching}>{submitButtonText}</Button>
        </Modal.Footer>
      </Form>
    </>
  )
}
PageMarginSettingUpsertForm.schema = Yup.object().shape({
  pageMarginSettingType: Yup.string().trim().required(),
  pageMarginOtherSettingId: Yup.number().when('pageMarginSettingType', {
    is: (pageMarginSettingType) => pageMarginSettingType === 'other',
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.nullable(true)
  }),
  order: Yup.number().required().min(0),
  name: Yup.string().trim().required()
})

export const PageMarginSettingDeleteForm = ({
  handleSubmit,
  title,
  submitButtonText,
  onClickCancel,
  selectedIds,
  pageMarginSettings,
  enumValues
}) => {
  const selectedIdSet = new Set(selectedIds)
  const deleteStyles = pageMarginSettings?.filter(s => selectedIdSet.has(s.id))
  // console.log('deleteStyles', { deleteStyles })
  return (
    <>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Form noValidate onSubmit={handleSubmit}>
        <Modal.Body>
          <p>以下の欄外項目を削除します。よろしいですか？</p>
          <ListGroup>
            {deleteStyles.map(style => (
              <ListGroup.Item key={style.id}>
                id: {style.id},
                種別: {enumValues.pageMarginSettingTypes[camelCase(style.pageMarginSettingType)]},
                名称: {style.name}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={onClickCancel}>
            キャンセル
          </Button>
          <Button type='submit'>{submitButtonText}</Button>
        </Modal.Footer>
      </Form>
    </>
  )
}

export const PageMarginOtherSettingForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleSubmit,
  isSettingWritable,
  selectedIds,
  dispatch,
  selectId,
  unselectId,
  rowIndex
}) => {
  if (!values) {
    return (<></>)
  }
  return (
    <Row sm='auto' className={`row-${rowIndex + 1}`}>
      <Col>
        <InputGroup size='sm' hasValidation>
          <Form.Control
            id={`nameInput-${values.id}`}
            type='text'
            name='name'
            value={values.name || ''}
            disabled={!isSettingWritable}
            onChange={handleChange}
            onBlur={(_) => handleSubmit(values)}
            isInvalid={touched.name && errors.name}
          />
          <Form.Control.Feedback type='invalid'>
            {errors.name}
          </Form.Control.Feedback>
        </InputGroup>
      </Col>
      <Col sm='auto'>
        <Form.Check.Input
          type='checkbox'
          name='checkedGroups'
          value={values.id}
          checked={selectedIds?.includes(parseInt(values.id))}
          onChange={e => dispatch((e.target.checked ? selectId : unselectId)(parseInt(e.target.value)))}
        />
      </Col>
    </Row>
  )
}
PageMarginOtherSettingForm.schema = Yup.object().shape({
  name: Yup.string().trim().required()
})

export const PageMarginOtherSettingUpsertForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  setFieldValue,
  isValid,
  fetching,
  enumValues,
  isSettingWritable,
  title,
  onClickCancel,
  submitButtonText,
  alert,
  largestPageMarginOtherOrder
}) => {
  return (
    <>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      {alert?.show && alert?.alertBody &&
        <Alert
          className='upsert-char-style-setting-errors-alert'
          variant='danger'
        >
          {alert.alertBody}
        </Alert>}
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Form.Group as={Row} className='mb-3'>
            <Form.Label column className='col-3'>欄外項目・その他の項目名称</Form.Label>
            <Col>
              <Form.Control
                type='text'
                name='name'
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.name && errors.name}
              />
              <Form.Control.Feedback type='invalid'>
                {errors.name}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={onClickCancel}>
            キャンセル
          </Button>
          <Button type='submit' disabled={!isValid || fetching}>{submitButtonText}</Button>
        </Modal.Footer>
      </Form>
    </>
  )
}
PageMarginOtherSettingUpsertForm.schema = Yup.object().shape({
  order: Yup.number().required().min(0),
  name: Yup.string().trim().required()
})

export const PageMarginOtherSettingDeleteForm = ({
  handleSubmit,
  title,
  submitButtonText,
  onClickCancel,
  selectedIds,
  pageMarginSettings,
  enumValues
}) => {
  const selectedIdSet = new Set(selectedIds)
  const deleteStyles = pageMarginSettings?.filter(s => selectedIdSet.has(s.id))
  // console.log('deleteStyles', { deleteStyles })
  return (
    <>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Form noValidate onSubmit={handleSubmit}>
        <Modal.Body>
          <p>以下の欄外項目を削除します。よろしいですか？</p>
          <ListGroup>
            {deleteStyles.map(style => (
              <ListGroup.Item key={style.id}>
                id: {style.id},
                種別: {enumValues.pageMarginSettingTypes[camelCase(style.pageMarginSettingType)]},
                名称: {style.name}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={onClickCancel}>
            キャンセル
          </Button>
          <Button type='submit'>{submitButtonText}</Button>
        </Modal.Footer>
      </Form>
    </>
  )
}

export const PreambleCharacterSettingForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  isSettingWritable,
  rowIndex
}) => {
  return (
    <Row sm='auto' className={`row-${rowIndex + 1}`}>
      <Col sm={2}>
        前文{values.columns}段
      </Col>
      <Col>
        <InputGroup size='sm' hasValidation>
          <Form.Control
            id='charactersInput'
            type='number'
            name='characters'
            value={values.characters || ''}
            onChange={(e) => {
              handleChange(e)
              handleSubmit(values)
            }}
            onBlur={handleBlur}
            isInvalid={touched.characters && errors.characters}
            disabled={!isSettingWritable}
          />
          <InputGroup.Text>字組</InputGroup.Text>
          <Form.Control.Feedback type='invalid'>
            {errors.characters}
          </Form.Control.Feedback>
        </InputGroup>
      </Col>
    </Row>
  )
}
PreambleCharacterSettingForm.schema = Yup.object().shape({
  characters: Yup.number().min(1).required()
})

export const PreambleRowMarginSettingForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  isSettingWritable,
  rowIndex
}) => {
  return (
    <Row sm='auto' className={`row-${rowIndex + 1}`}>
      <Col sm={2}>
        前文{values.rows}行
      </Col>
      <Col>
        <InputGroup size='sm' hasValidation>
          <Form.Control
            id='marginRowsInput'
            type='number'
            name='marginRows'
            value={values.marginRows || ''}
            onChange={(e) => {
              handleChange(e)
              handleSubmit(values)
            }}
            onBlur={handleBlur}
            isInvalid={touched.marginRows && errors.marginRows}
            disabled={!isSettingWritable}
          />
          <InputGroup.Text>行取り</InputGroup.Text>
          <Form.Control.Feedback type='invalid'>
            {errors.marginRows}
          </Form.Control.Feedback>
        </InputGroup>
      </Col>
    </Row>
  )
}
PreambleRowMarginSettingForm.schema = Yup.object().shape({
  marginRows: Yup.number().min(1).required()
})

export const FigureRowMarginSettingForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  enumValues,
  isSettingWritable,
  rowIndex
}) => {
  const columnString = getColumnString(values.columns)
  const orientationString = enumValues.headingOrientations[values.orientation]
  const combinedColumnString = `${columnString}${orientationString}写真`
  return (
    <Row sm='auto' className={`row-${rowIndex + 1}`}>
      <Col sm={2}>
        {combinedColumnString}
      </Col>
      <Col>
        <InputGroup size='sm' hasValidation>
          <Form.Control
            id='marginRowsInput'
            type='number'
            name='marginRows'
            value={values.marginRows || ''}
            onChange={(e) => {
              handleChange(e)
              handleSubmit(values)
            }}
            onBlur={handleBlur}
            isInvalid={touched.marginRows && errors.marginRows}
            disabled={!isSettingWritable}
          />
          <InputGroup.Text>行取り</InputGroup.Text>
          <Form.Control.Feedback type='invalid'>
            {errors.marginRows}
          </Form.Control.Feedback>
        </InputGroup>
      </Col>
    </Row>
  )
}
FigureRowMarginSettingForm.schema = Yup.object().shape({
  marginRows: Yup.number().min(1).required()
})

export const LineRowMarginSettingForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  isSettingWritable,
  rowIndex
}) => {
  return (
    <Row sm='auto' className={`row-${rowIndex + 1}`}>
      <Col sm={2}>
        {values.columns}段罫線
      </Col>
      <Col>
        <InputGroup size='sm' hasValidation>
          <Form.Control
            id='marginRowsInput'
            type='number'
            name='marginRows'
            value={values.marginRows || ''}
            onChange={(e) => {
              handleChange(e)
              handleSubmit(values)
            }}
            onBlur={handleBlur}
            isInvalid={touched.marginRows && errors.marginRows}
            disabled={!isSettingWritable}
          />
          <InputGroup.Text>行取り</InputGroup.Text>
          <Form.Control.Feedback type='invalid'>
            {errors.marginRows}
          </Form.Control.Feedback>
        </InputGroup>
      </Col>
    </Row>
  )
}
LineRowMarginSettingForm.schema = Yup.object().shape({
  marginRows: Yup.number().min(1).required()
})

export const AuxiliaryLineSettingForm = ({
  values,
  errors,
  touched,
  handleBlur,
  handleSubmit,
  setFieldValue,
  isSettingWritable,
  enumValues,
  rowIndex
}) => {
  return (
    <Row sm='auto' className={`row-${rowIndex + 1}`}>
      <Col sm={2}>
        {enumValues.priorities[values.priority]}記事
      </Col>
      <Form.Group as={Col} sm='auto'>
        <InputGroup size='sm' hasValidation>
          <Form.Control
            type='number'
            name='startColumn'
            value={
              (values.startColumn !== undefined &&
               values.startColumn !== '')
                ? parseInt(values.startColumn) + 1
                : ''
            }
            onChange={(e) => {
              const startColumn = e.target.value !== '' ? (parseInt(e.target.value) - 1) : ''
              setFieldValue('startColumn', startColumn)
              const newValues = { ...values, startColumn }
              if (AuxiliaryLineSettingForm.schema.isValidSync(newValues)) {
                handleSubmit(newValues)
              }
            }}
            disabled={!isSettingWritable}
            onBlur={handleBlur}
            isInvalid={touched.startColumn && errors.startColumn}
          />
          <InputGroup.Text>段目</InputGroup.Text>
          <Form.Control.Feedback type='invalid'>
            {errors.startColumn}
          </Form.Control.Feedback>
        </InputGroup>
      </Form.Group>
      <Form.Group as={Col} sm='auto'>
        <InputGroup size='sm' hasValidation>
          <Form.Control
            type='number'
            name='startRow'
            value={
              (values.startRow !== undefined &&
               values.startRow !== '')
                ? parseInt(values.startRow) + 1
                : ''
            }
            onChange={(e) => {
              const startRow = e.target.value !== '' ? (parseInt(e.target.value) - 1) : ''
              setFieldValue('startRow', startRow)
              const newValues = { ...values, startRow }
              if (AuxiliaryLineSettingForm.schema.isValidSync(newValues)) {
                handleSubmit(newValues)
              }
            }}
            disabled={!isSettingWritable}
            onBlur={handleBlur}
            isInvalid={touched.startRow && errors.startRow}
          />
          <InputGroup.Text>行目</InputGroup.Text>
          <Form.Control.Feedback type='invalid'>
            {errors.startRow}
          </Form.Control.Feedback>
        </InputGroup>
      </Form.Group>
      <Form.Group as={Col} sm='auto'>
        <InputGroup size='sm' hasValidation>
          <Form.Control
            type='number'
            name='length'
            value={values.length || ''}
            onChange={e => {
              const length = parseInt(e.target.value)
              setFieldValue('length', length)
              const newValues = { ...values, length }
              // console.log({ values, newValues })
              if (AuxiliaryLineSettingForm.schema.isValidSync(newValues)) {
                handleSubmit(newValues)
              }
            }}
            disabled={!isSettingWritable}
            onBlur={handleBlur}
            isInvalid={touched.length && errors.length}
          />
          <InputGroup.Text>段</InputGroup.Text>
          <Form.Control.Feedback type='invalid'>
            {errors?.length}
          </Form.Control.Feedback>
        </InputGroup>
      </Form.Group>
    </Row>
  )
}

const startPosErrorMessage = '1以上にしてください'
AuxiliaryLineSettingForm.schema = Yup.object().shape({
  priority: Yup.string().required(),
  startColumn: Yup.number().required().min(0, startPosErrorMessage),
  startRow: Yup.number().required().min(0, startPosErrorMessage),
  length: Yup.number().required().min(1)
})

export const HalfSizeImageCharacterSettingForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  isValid,
  isSettingWritable
}) => {
  return (
    <Form
      noValidate
      onSubmit={handleSubmit}
    >
      <Row sm='auto'>
        <Col sm={2}>
          画像字数
        </Col>
        <Col>
          <InputGroup size='sm' hasValidation>
            <Form.Control
              type='number'
              name='imageCharacters'
              value={values?.imageCharacters || ''}
              onBlur={handleBlur}
              onChange={handleChange}
              isInvalid={touched?.imageCharacters && errors?.imageCharacters}
              disabled={!isSettingWritable}
            />
            <InputGroup.Text>字</InputGroup.Text>
            <Form.Control.Feedback type='invalid'>
              {errors?.imageCharacters}
            </Form.Control.Feedback>
          </InputGroup>
        </Col>
      </Row>
      <Row sm='auto'>
        <Col sm={2}>
          空白字数
        </Col>
        <Col>
          <InputGroup size='sm' hasValidation>
            <Form.Control
              type='number'
              name='spaceCharacters'
              value={values?.spaceCharacters || ''}
              readOnly
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched?.spaceCharacters && errors?.spaceCharacters}
              disabled={!isSettingWritable}
            />
            <InputGroup.Text>字</InputGroup.Text>
            <Form.Control.Feedback type='invalid'>
              {errors?.spaceCharacters}
            </Form.Control.Feedback>
          </InputGroup>
        </Col>
      </Row>
      <Row sm='auto'>
        <Col sm={2}>
          記事字数
        </Col>
        <Col>
          <InputGroup size='sm' hasValidation>
            <Form.Control
              type='number'
              name='textCharacters'
              value={values?.textCharacters || ''}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched?.textCharacters && errors?.textCharacters}
              disabled={!isSettingWritable}
            />
            <InputGroup.Text>字</InputGroup.Text>
            <Form.Control.Feedback type='invalid'>
              {errors?.textCharacters}
            </Form.Control.Feedback>
          </InputGroup>
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <Button type='submit' disabled={!isSettingWritable || !isValid || _.isEmpty(touched)} size='sm'>更新</Button>
        </Col>
      </Row>
    </Form>
  )
}
HalfSizeImageCharacterSettingForm.schema = Yup.object().shape({
  imageCharacters: Yup.number().required().min(1),
  spaceCharacters: Yup.number().required().min(1),
  textCharacters: Yup.number().required().min(1)
})
