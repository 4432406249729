/* eslint-env browser */
import {
  useEffect,
  useRef
} from 'react'

import * as lexical from 'lexical'
import {
  $generateNodesFromDOM
} from '@lexical/html'
import {
  useLexicalComposerContext
} from '@lexical/react/LexicalComposerContext'

export const PrepopulatePlugin = (props) => {
  const [editor] = useLexicalComposerContext()
  const isFirstRender = useRef(true)

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false

      editor.update(() => {
        const parser = new DOMParser()
        const source = props.initialTaggedContent?.replace(/<sr\s*\/>/g, '<br />') || ''
        const dom = parser.parseFromString(source, 'text/html')
        const nodes = $generateNodesFromDOM(editor, dom)
        lexical.$selectAll()
        lexical.$insertNodes(nodes, null)
        lexical.$getRoot().select(0, 0)
      })
    }
  }, [isFirstRender.current, editor])

  return null
}
