import { createAsyncThunk } from '@reduxjs/toolkit'
import { snakeCase } from 'lodash'
import toCamel from 'lodash-humps'
import {
  csrfToken,
  toSnake,
  throwErrorForErrorResponse,
  errorHandler
} from '../common'
import { closeModal } from './articleSlice'

export const getLayoutableBaseUrl = ({ layoutableType, layoutableId }) => {
  if (layoutableType === undefined || layoutableId === undefined) {
    return undefined
  }
  return `${snakeCase(layoutableType)}s/${layoutableId}`
}

export const fetchArticles = createAsyncThunk(
  'article/fetchArticles',
  async (params, { getState, rejectWithValue }) => {
    const { baseUri } = getState().common
    // const { layoutableId, layoutableType } = params
    const layoutableBaseUrl = getLayoutableBaseUrl(params)
    return fetch(
      `${baseUri}${layoutableBaseUrl}/articles`, {
        headers: {
          Accept: 'application/json'
        }
      })
      .then(throwErrorForErrorResponse)
      .then(response => response.json())
      .then(toCamel)
      .catch(errorHandler(rejectWithValue))
  }
)

export const fetchLayoutableStatus = createAsyncThunk(
  'article/fetchLayoutableStatus',
  async (params, { getState, rejectWithValue }) => {
    const { baseUri } = getState().common
    const layoutableBaseUrl = getLayoutableBaseUrl(params)
    return fetch(
      `${baseUri}${layoutableBaseUrl}/status`, {
        headers: {
          Accept: 'application/json'
        }
      })
      .then(throwErrorForErrorResponse)
      .then(response => response.json())
      .then(toCamel)
      .catch(errorHandler(rejectWithValue))
  }
)
export const createArticle = createAsyncThunk(
  'article/createArticle',
  async (params, { getState, rejectWithValue, dispatch }) => {
    const { baseUri } = getState().common
    const { formData } = params
    const layoutableBaseUrl = getLayoutableBaseUrl(params)
    return fetch(
      `${baseUri}${layoutableBaseUrl}/articles`, {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'X-CSRF-Token': csrfToken()
        },
        body: formData
      })
      .then(throwErrorForErrorResponse)
      .then(() => {
        const { currentPage, sort } = getState().article
        dispatch(fetchArticles({ ...params, page: currentPage, sort }))
        return Promise.resolve({})
      })
      .then(toCamel)
      .catch(errorHandler(rejectWithValue))
      .finally(() => dispatch(closeModal()))
  }
)

export const bulkCreateArticles = createAsyncThunk(
  'article/bulkCreateArticles',
  async (params, { getState, rejectWithValue, dispatch }) => {
    const { baseUri } = getState().common
    const { formData } = params
    const layoutableBaseUrl = getLayoutableBaseUrl(params)
    return fetch(
      `${baseUri}${layoutableBaseUrl}/articles/upload`, {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'X-CSRF-Token': csrfToken()
        },
        body: formData
      })
      .then(throwErrorForErrorResponse)
      .then(() => {
        const { currentPage, sort } = getState().article
        dispatch(fetchArticles({ ...params, page: currentPage, sort }))
        return Promise.resolve({})
      })
      .then(toCamel)
      .catch(errorHandler(rejectWithValue))
      .finally(() => dispatch(closeModal()))
  }
)

export const modifyArticle = createAsyncThunk(
  'article/modifyArticle',
  async (params, { getState, rejectWithValue, dispatch }) => {
    const { baseUri } = getState().common
    const { articleId, formData } = params
    const layoutableBaseUrl = getLayoutableBaseUrl(params)

    return fetch(
        `${baseUri}${layoutableBaseUrl}/articles/${articleId}`, {
          method: 'put',
          headers: {
            Accept: 'application/json',
            'X-CSRF-Token': csrfToken()
          },
          body: formData
        })
      .then(throwErrorForErrorResponse)
      .then(() => {
        const { currentPage, sort } = getState().article
        dispatch(fetchArticles({ ...params, page: currentPage, sort }))
        const alertBody = '記事の変更が保存されました'
        return Promise.resolve({ alert: { alertBody } })
      })
      .then(toCamel)
      .catch(errorHandler(rejectWithValue))
      .finally(() => dispatch(closeModal()))
  }
)

export const modifyPage = createAsyncThunk(
  'article/modifyPage',
  async (params, { getState, rejectWithValue, dispatch }) => {
    const { baseUri } = getState().common
    const layoutableBaseUrl = getLayoutableBaseUrl(params)
    const { formData } = params
    return fetch(
      `${baseUri}${layoutableBaseUrl}`, {
        method: 'put',
        headers: {
          Accept: 'application/json',
          'X-CSRF-Token': csrfToken()
        },
        body: formData
      })
      .then(throwErrorForErrorResponse)
      .then(() => {
        const { currentPage, sort } = getState().page
        dispatch(fetchArticles({ ...params, page: currentPage, sort }))
        const alertBody = '紙面の設定が保存されました'
        return Promise.resolve({ alert: { alertBody } })
      })
      .then(toCamel)
      .catch(errorHandler(rejectWithValue))
      .finally(() => dispatch(closeModal()))
  }
)

export const deleteArticles = createAsyncThunk(
  'article/deleteArticles',
  async (params, { getState, rejectWithValue, dispatch }) => {
    const { baseUri } = getState().common
    const layoutableBaseUrl = getLayoutableBaseUrl(params)
    const { layoutableLockVersion, selectedIds } = params
    const deleteParams = { articleIds: selectedIds, lockVersion: layoutableLockVersion }
    return fetch(
        `${baseUri}${layoutableBaseUrl}/articles`, {
          method: 'delete',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-CSRF-Token': csrfToken()
          },
          body: JSON.stringify(toSnake(deleteParams))
        })
      .then(throwErrorForErrorResponse)
      .then(() => {
        const { currentPage, sort } = getState().article
        dispatch(fetchArticles({ ...params, page: currentPage, sort }))
        return Promise.resolve({})
      })
      .then(toCamel)
      .catch(errorHandler(rejectWithValue))
      .finally(() => dispatch(closeModal()))
  }
)

export const switchArticleOrders = createAsyncThunk(
  'article/switchArticleOrders',
  async (params, { getState, rejectWithValue, dispatch }) => {
    const { baseUri } = getState().common
    const layoutableBaseUrl = getLayoutableBaseUrl(params)
    return fetch(
        `${baseUri}${layoutableBaseUrl}/articles/switch_orders`, {
          method: 'PATCH',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-CSRF-Token': csrfToken()
          },
          body: JSON.stringify(toSnake(params))
        })
      .then(throwErrorForErrorResponse)
      .then(() => {
        const { currentPage, sort } = getState().article
        dispatch(fetchArticles({ ...params, page: currentPage, sort }))
        return Promise.resolve({})
      })
      .then(toCamel)
      .catch(errorHandler(rejectWithValue))
      .finally(() => dispatch(closeModal()))
  }
)

export const createFigure = createAsyncThunk(
  'article/createFigure',
  async (params, { getState, rejectWithValue, dispatch }) => {
    const { baseUri } = getState().common
    const { layoutableId, layoutableType } = params
    return fetch(
      `${baseUri}figures`, {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken()
        },
        body: JSON.stringify(toSnake(params))
      })
      .then(throwErrorForErrorResponse)
      .then(() => {
        const { currentPage, sort } = getState().article
        dispatch(fetchArticles({ layoutableId, layoutableType, page: currentPage, sort }))
        return Promise.resolve({})
      })
      .then(toCamel)
      .catch(errorHandler(rejectWithValue))
      .finally(() => dispatch(closeModal()))
  }
)

export const modifyFigure = createAsyncThunk(
  'article/modifyFigure',
  async (params, { getState, rejectWithValue, dispatch }) => {
    const { baseUri } = getState().common
    const { figure } = params
    const { id } = figure
    return fetch(
      `${baseUri}figures/${id}`, {
        method: 'put',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken()
        },
        body: JSON.stringify(toSnake(figure))
      })
      .then(throwErrorForErrorResponse)
      .then(() => {
        dispatch(fetchArticles(params))
        const alertBody = '写真・図の変更が保存されました'
        return Promise.resolve({ alert: { alertBody } })
      })
      .then(toCamel)
      .catch(errorHandler(rejectWithValue))
      .finally(() => dispatch(closeModal()))
  }
)

export const deleteFigure = createAsyncThunk(
  'article/deleteFigure',
  async (params, { getState, rejectWithValue, dispatch }) => {
    const { baseUri } = getState().common
    const { figure, showAlert } = params
    const { id } = figure
    return fetch(
      `${baseUri}figures/${id}`, {
        method: 'delete',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken()
        },
        body: JSON.stringify(toSnake(params))
      })
      .then(throwErrorForErrorResponse)
      .then(() => {
        dispatch(fetchArticles(params))
        if (!showAlert) {
          return Promise.resolve({})
        }
        const alertBody = '写真・図が削除されました'
        return Promise.resolve({ alert: { alertBody } })
      })
      .then(toCamel)
      .catch(errorHandler(rejectWithValue))
      .finally(() => dispatch(closeModal()))
  }
)

export const modifyArea = createAsyncThunk(
  'article/modifyArea',
  async (params, { getState, rejectWithValue, dispatch }) => {
    const { baseUri } = getState().common
    const { area } = params
    const { id } = area
    return fetch(
      `${baseUri}areas/${id}`, {
        method: 'put',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken()
        },
        body: JSON.stringify(toSnake(area))
      })
      .then(throwErrorForErrorResponse)
      .then(() => {
        dispatch(fetchArticles(params))
        const alertBody = '領域の変更が保存されました'
        return Promise.resolve({ alert: { alertBody } })
      })
      .then(toCamel)
      .catch(errorHandler(rejectWithValue))
      .finally(() => dispatch(closeModal()))
  }
)

export const modifyHeading = createAsyncThunk(
  'article/modifyHeading',
  async (params, { getState, rejectWithValue, dispatch }) => {
    const { baseUri } = getState().common
    const { heading } = params
    const { id } = heading
    return fetch(
      `${baseUri}headings/${id}`, {
        method: 'put',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken()
        },
        body: JSON.stringify(toSnake(params))
      })
      .then(throwErrorForErrorResponse)
      .then(() => {
        const layoutableBaseUrl = getLayoutableBaseUrl(params)
        if (layoutableBaseUrl !== undefined) {
          dispatch(fetchArticles(params))
        }
        const alertBody = '見出しの変更が保存されました'
        return Promise.resolve({ alert: { alertBody } })
      })
      .then(toCamel)
      .catch(errorHandler(rejectWithValue))
      .finally(() => dispatch(closeModal()))
  }
)

export const modifyTemplateArticle = createAsyncThunk(
  'article/modifyTemplateArticle',
  async (params, { getState, rejectWithValue, dispatch }) => {
    const { baseUri } = getState().common
    const { templateArticle } = params
    const { id } = templateArticle
    return fetch(
      `${baseUri}template_articles/${id}`, {
        method: 'put',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken()
        },
        body: JSON.stringify(toSnake(params))
      })
      .then(throwErrorForErrorResponse)
      .then(() => {
        dispatch(fetchArticles(params))
        const alertBody = '在版記事の変更が保存されました'
        return Promise.resolve({ alert: { alertBody } })
      })
      .then(toCamel)
      .catch(errorHandler(rejectWithValue))
      .finally(() => dispatch(closeModal()))
  }
)

export const startLayout = createAsyncThunk(
  'article/startLayout',
  async (params, { getState, rejectWithValue, dispatch }) => {
    const { baseUri } = getState().common
    const layoutableBaseUrl = getLayoutableBaseUrl(params)

    return fetch(
      `${baseUri}${layoutableBaseUrl}/start_layout`, {
        method: 'put',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken()
        },
        body: JSON.stringify(toSnake(params))
      })
      .then(throwErrorForErrorResponse)
      .then(() => {
        const { currentPage, sort } = getState().article
        dispatch(fetchArticles({ ...params, page: currentPage, sort }))
        return Promise.resolve({})
      })
      .catch(errorHandler(rejectWithValue))
      .finally(() => dispatch(closeModal()))
  }
)

export const cancelLayout = createAsyncThunk(
  'article/cancelLayout',
  async (params, { getState, rejectWithValue, dispatch }) => {
    const { baseUri } = getState().common
    const layoutableBaseUrl = getLayoutableBaseUrl(params)

    return fetch(
      `${baseUri}${layoutableBaseUrl}/cancel_layout`, {
        method: 'put',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken()
        },
        body: JSON.stringify(toSnake(params))
      })
      .then(throwErrorForErrorResponse)
      .then(() => {
        const { currentPage, sort } = getState().article
        dispatch(fetchArticles({ ...params, page: currentPage, sort }))
        const alertBody = '記事の割り付けをキャンセルしました'
        return Promise.resolve({ alert: { alertBody } })
      })
      .then(toCamel)
      .catch(errorHandler(rejectWithValue))
      .finally(() => dispatch(closeModal()))
  }
)

export const modifyLayoutLocked = createAsyncThunk(
  'article/modifyLayoutLocked',
  async (params, { getState, rejectWithValue, dispatch }) => {
    const { baseUri } = getState().common
    const { article } = params
    const layoutableBaseUrl = getLayoutableBaseUrl(params)

    return fetch(
      `${baseUri}${layoutableBaseUrl}/articles/${article.id}/lock_layout`, {
        method: 'put',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken()
        },
        body: JSON.stringify(toSnake(params))
      })
      .then(throwErrorForErrorResponse)
      .then(() => {
        const { currentPage, sort } = getState().article
        dispatch(fetchArticles({ ...params, page: currentPage, sort }))
        const alertBody = '記事の変更が保存されました'
        return Promise.resolve({ alert: { alertBody } })
      })
      .then(toCamel)
      .catch(errorHandler(rejectWithValue))
      .finally(() => dispatch(closeModal()))
  }
)
