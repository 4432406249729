import React from 'react'
import { useDispatch } from 'react-redux'
import {
  Button,
  Form,
  InputGroup,
  Row,
  Col
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as Yup from 'yup'
import * as ja from 'yup-locale-ja'
import {
  getNumberOfFullChars,
  handlePreamble,
  stripTags,
  getParagraphStyleName,
  changeParagraphStyleMultiline
} from './ArticleParse'
import { modifyArticle } from './articleAPI'
import {
  selectCell,
  closeModal,
  openModal
} from './articleSlice'
import {
  TagEditor,
  getSubmitParamForArticleUpsert
} from '../article'
import {
  ConfirmationModal
} from '../common'
import { shouldShowExperimentalFeatures } from '../auth'
Yup.setLocale(ja.suggestive)

export const ArticlePreambleForm = ({
  values,
  errors,
  touched,
  isValid,
  handleChange,
  handleBlur,
  handleSubmit,
  isSelectedLayoutableWritable,
  setFieldValue,
  layoutable,
  layoutableType,
  layoutableId,
  openedModal
}) => {
  const dispatch = useDispatch()
  if (values.article.preambleContent === null) {
    return (
      <Row>
        <Col>
          前文なし
        </Col>
        <Col>
          <Button
            variant='primary'
            size='sm'
            disabled={!isSelectedLayoutableWritable || values.article?.layoutLocked}
            onClick={(e) => {
              e.preventDefault()
              const preambleContent = ''
              const preambleTaggedContent = ''
              const newArticle = {
                ...(values.article),
                preambleContent,
                preambleTaggedContent
              }
              const props = { layoutableType, layoutableId, layoutableLockVersion: layoutable?.lockVersion, article: newArticle }
              const submitParam = getSubmitParamForArticleUpsert(props)
              dispatch(modifyArticle(submitParam))
              dispatch(selectCell(null))
            }}
          >
            <FontAwesomeIcon icon='plus' fixedWidth /> 追加
          </Button>
        </Col>
      </Row>
    )
  }
  const preambleFullWidthCharacters = getNumberOfFullChars(values.article?.preambleContent)
  const onTaggedContentSubmit = (taggedContent) => {
    const preambleContent = stripTags(taggedContent)
    const preambleNumberOfColumns = values.preambleNumberOfColumns
    const {
      preambleNumberOfCharactersPerRow, preambleNumberOfRows, preambleMarginRows
    } = handlePreamble({
      numberOfCharactersPerRow: layoutable.numberOfCharactersPerRow,
      setting: layoutable.setting,
      preamble: preambleContent,
      preambleTaggedContent: taggedContent,
      preambleNumberOfColumns
    })
    const paragraphStyleName = getParagraphStyleName(layoutable.setting, 'preamble')
    const preambleTaggedContent = changeParagraphStyleMultiline(taggedContent, paragraphStyleName)
    const newArticle = {
      ...(values.article),
      preambleContent,
      preambleTaggedContent,
      preambleNumberOfCharactersPerRow,
      preambleNumberOfRows,
      preambleMarginRows
    }
    const props = { layoutableType, layoutableId, layoutableLockVersion: layoutable?.lockVersion, article: newArticle }
    const submitParam = getSubmitParamForArticleUpsert(props)
    dispatch(modifyArticle(submitParam))
    dispatch(selectCell(null))
  }
  const inputDisabled = !isSelectedLayoutableWritable || values.article.layoutLocked
  const editable = !inputDisabled && shouldShowExperimentalFeatures()
  const handleRemovePreambleSubmit = () => {
    const newArticle = {
      ...(values.article),
      preambleContent: null,
      preambleTaggedContent: null,
      preambleNumberOfCharactersPerRow: null,
      preambleNumberOfRows: null,
      preambleMarginRows: null
    }
    const props = { layoutableType, layoutableId, layoutableLockVersion: layoutable?.lockVersion, article: newArticle }
    const submitParam = getSubmitParamForArticleUpsert(props)
    dispatch(modifyArticle(submitParam))
    dispatch(selectCell(null))
  }
  const removePreambleModalSetting = {
    name: 'remove-preamble',
    key: 'remove-preamble-modal',
    form: {
      initialValues: {
        layoutableId,
        layoutableType
      },
      handleSubmit: handleRemovePreambleSubmit,
      props: {
        layoutable,
        title: '前文削除',
        submitButtonText: '前文を削除する',
        onClickCancel: () => dispatch(closeModal()),
        ConfirmationBody: () => {
          return (
            <>前文を削除しますか？</>
          )
        }
      }
    }
  }
  return (
    <>
      <Form noValidate onSubmit={handleSubmit}>
        <Row className='mb-2'>
          <Col>
            <div className='preambleCharacters fs-5'>前文文字数: {preambleFullWidthCharacters}</div>
          </Col>
          <Col>
            <div className='preambleNumberOfRows fs-5'>前文行数: {values.preambleNumberOfRows}</div>
          </Col>
        </Row>
        <Row className='mb-2'>
          <Form.Group as={Col}>
            <InputGroup size='sm' hasValidation>
              <InputGroup.Text>縦</InputGroup.Text>
              <Form.Control
                id='preambleColumnsInput'
                type='number'
                name='preambleNumberOfColumns'
                value={values.preambleNumberOfColumns || ''}
                onChange={(e) => {
                  const preambleNumberOfColumns = e.target.value
                  const {
                    preambleNumberOfCharactersPerRow, preambleNumberOfRows, preambleMarginRows
                  } = handlePreamble({
                    numberOfCharactersPerRow: layoutable.numberOfCharactersPerRow,
                    setting: layoutable.setting,
                    preamble: values.article.preambleContent,
                    preambleTaggedContent: values.article.preambleTaggedContent,
                    preambleNumberOfColumns
                  })
                  setFieldValue('preambleNumberOfCharactersPerRow', preambleNumberOfCharactersPerRow)
                  setFieldValue('preambleNumberOfRows', preambleNumberOfRows)
                  setFieldValue('preambleMarginRows', preambleMarginRows)
                  handleChange(e)
                }}
                onBlur={handleBlur}
                isInvalid={touched.preambleNumberOfColumns && errors.preambleNumberOfColumns}
                disabled={inputDisabled}
              />
              <InputGroup.Text>段</InputGroup.Text>
              <Form.Control.Feedback type='invalid'>
                {errors.preambleNumberOfColumns}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <Form.Group as={Col}>
            <InputGroup size='sm' hasValidation>
              <Form.Control
                id='preambleNumberOfCharactersPerRowInput'
                type='number'
                name='preambleNumberOfCharactersPerRow'
                value={values.preambleNumberOfCharactersPerRow || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.preambleNumberOfCharactersPerRow && errors.preambleNumberOfCharactersPerRow}
                disabled={inputDisabled}
              />
              <InputGroup.Text>字組</InputGroup.Text>
              <Form.Control.Feedback type='invalid'>
                {errors.preambleNumberOfCharactersPerRow}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <Form.Group as={Col}>
            <InputGroup size='sm' hasValidation>
              <Form.Control
                id='preambleMarginRowsInput'
                type='number'
                name='preambleMarginRows'
                value={values.preambleMarginRows || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.preambleMarginRows && errors.preambleMarginRows}
                disabled={inputDisabled}
              />
              <InputGroup.Text>行取り</InputGroup.Text>
              <Form.Control.Feedback type='invalid'>
                {errors.preambleMarginRows}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <Col sm='auto'>
            <Button
              type='submit'
              disabled={inputDisabled || !isValid ||
                        (!touched.preambleNumberOfColumns && !touched.preambleMarginRows && !touched.preambleNumberOfCharactersPerRow)}
              size='sm'
            >
              保存
            </Button>
          </Col>
        </Row>
        <Row className='mt-3'>
          <Col>
            <TagEditor
              initialTaggedContent={values.article.preambleTaggedContent}
              styleTagType='sentence'
              setting={layoutable.setting}
              onSubmit={onTaggedContentSubmit}
              disabled={inputDisabled}
              editable={editable}
              allowLineBreak
              allowMultiParagraph
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <ConfirmationModal key={removePreambleModalSetting.key} {...removePreambleModalSetting} openedModal={openedModal} />
            <Button
              variant='danger'
              size='sm'
              disabled={!isSelectedLayoutableWritable || values.article?.layoutLocked}
              onClick={(e) => {
                e.preventDefault()
                dispatch(openModal('remove-preamble'))
              }}
            >
              <FontAwesomeIcon icon='trash' fixedWidth /> 前文削除
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  )
}

ArticlePreambleForm.schema = Yup.object().shape({
  preambleNumberOfColumns: Yup.number().required().min(1),
  preambleNumberOfRows: Yup.number().required().min(1),
  preambleNumberOfCharactersPerRow: Yup.number().min(1),
  preambleMarginRows: Yup.number().min(1)
})
