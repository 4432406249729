import _ from 'lodash'
import * as jiji from './jijiText'
import { parse, SyntaxError } from './inputText'
import {
  HeadingType, Orientation, ArticleType, Priority, DividerLineMode, RelationType, Status
} from '../common/enums'
export {
  HeadingType, Orientation, ArticleType, Priority, DividerLineMode, RelationType
}
export { SyntaxError } from './inputText'

export const getNumberOfFullChars = (str) => {
  if (!str) {
    return 0
  }
  let chars = 0
  for (let i = 0; i < str.length; i++) {
    const c = str.charCodeAt(i)
    chars += (c >= 0x0020 && c <= 0x1FFF) ? 1 : 2
  }
  return chars / 2
}

export const getNumberOfRows = (
  numberOfCharactersPerRow,
  str,
  isTaggedContent = false
) => {
  let contentString
  if (isTaggedContent) {
    const srReplacedStr = str?.replace(/<sr\s*\/>/g, '\n')
    contentString = stripTags(srReplacedStr)
    // console.log({ str, srReplacedStr, contentString })
  } else {
    contentString = str
  }
  const paragraphs = contentString?.split(/\r?\n/)
  // console.log({ contentString, paragraphs })
  return paragraphs?.map(para => para === '' ? 1 : Math.ceil(getNumberOfFullChars(para) / numberOfCharactersPerRow))
    ?.reduce((prev, current) => prev + current, 0)
}

export const handlePreamble = ({
  numberOfCharactersPerRow,
  setting,
  preamble,
  preambleTaggedContent,
  preambleNumberOfColumns
}) => {
  if (preamble === undefined) {
    return {
      preambleContent: null,
      preambleNumberOfCharactersPerRow: null,
      preambleNumberOfRows: null,
      preambleMarginRows: null
    }
  }
  const preambleNumberOfColumnsInt = parseInt(preambleNumberOfColumns)
  // console.log('preambleNumberOfColumns', preambleNumberOfColumnsInt)
  const preambleNumberOfCharactersPerRow = (
    setting?.preambleCharacterSettings?.find(s => s.columns === preambleNumberOfColumnsInt)?.characters ||
      (preambleNumberOfColumnsInt * numberOfCharactersPerRow))
  const preambleNumberOfRows = preambleTaggedContent !== undefined
    ? getNumberOfRows(preambleNumberOfCharactersPerRow, preambleTaggedContent, true)
    : getNumberOfRows(preambleNumberOfCharactersPerRow, preamble)
  const preambleMarginRows = setting?.preambleRowMarginSettings?.find(s => s.rows === preambleNumberOfRows)?.marginRows || (preambleNumberOfRows + 1)
  return {
    preambleContent: preamble,
    preambleNumberOfCharactersPerRow,
    preambleNumberOfRows,
    preambleMarginRows
  }
}

export const stripTags = (taggedStr) => {
  // console.log('taggedStr', taggedStr)
  const newlineAdded = taggedStr?.replace(/<\/p>/g, '</p>\n')
  // console.log('newlineAdded', newlineAdded)
  const cleanedStr = newlineAdded?.replace(/<\/?[^>]+(>|$)/g, '').trim()
  // console.log('cleanedStr', cleanedStr)
  return cleanedStr
}

const PrioritySortOrderUndefined = 8
const PrioritySortOrder = Object.freeze({
  [Priority.Head]: 1,
  [Priority.Shoulder]: 2,
  [Priority.Neck]: 3,
  [Priority.Navel]: 4,
  [Priority.RightFoot]: 5,
  [Priority.LeftFoot]: 6,
  [Priority.Other]: 7
})

const defaultHeadingTypeMapRules = {
  Page: {
    0: [],
    1: [
      HeadingType.Primary
    ],
    2: [
      HeadingType.Primary,
      HeadingType.Secondary
    ],
    3: [
      HeadingType.TopCovering,
      HeadingType.Primary,
      HeadingType.Secondary
    ],
    4: [
      HeadingType.TopCovering,
      HeadingType.Pillar,
      HeadingType.Primary,
      HeadingType.Secondary
    ],
    5: [
      HeadingType.TopCovering,
      HeadingType.Pillar,
      HeadingType.Primary,
      HeadingType.Secondary,
      HeadingType.BottomCovering
    ],
    6: [
      HeadingType.Preamble,
      HeadingType.TopCovering,
      HeadingType.Pillar,
      HeadingType.Primary,
      HeadingType.Secondary,
      HeadingType.BottomCovering
    ],
    7: [
      HeadingType.Designed,
      HeadingType.Preamble,
      HeadingType.TopCovering,
      HeadingType.Pillar,
      HeadingType.Primary,
      HeadingType.Secondary,
      HeadingType.BottomCovering
    ]
  },
  SubLayout: {
    3: [
      HeadingType.Pillar,
      HeadingType.Primary,
      HeadingType.Secondary
    ]
  }
}

export const normalHeadingTypes = new Set([HeadingType.Pillar, HeadingType.Primary, HeadingType.Secondary])
export const coveringHeadingTypes = new Set([HeadingType.TopCovering, HeadingType.BottomCovering])
export const sizeCheckRequiredHeadingTypes = new Set([HeadingType.Designed, HeadingType.Preamble])
export const specialHeadingTypes = new Set([HeadingType.Designed, HeadingType.Preamble])

export const getParagraphStyleName = (setting, lookupType) => {
  return setting
    ?.paragraphStyleSettings
    ?.find(s => s.paragraphStyleSettingType === lookupType)
    ?.name
}

const findHeadingParagraphStyleName = ({ heading, headingNumberOfOccupyingColumns, setting }) => {
  const headingType = _.snakeCase(heading.headingType)
  if (heading.orientation === Orientation.Vertical) {
    const headingLookupColumns = (normalHeadingTypes.has(headingType) || coveringHeadingTypes.has(headingType))
      ? parseInt(headingNumberOfOccupyingColumns)
      : (headingType === HeadingType.Designed) ? 0 : parseInt(heading?.numberOfOccupyingColumns || 1)
    const lookupType = coveringHeadingTypes.has(headingType)
      ? 'heading_covering'
      : `heading_${headingType}`
    // console.log('findHeadingParagraphStyleName', { headingType, headingLookupColumns, lookupType })
    return setting?.paragraphStyleSettings
      ?.find(s => s.paragraphStyleSettingType === lookupType && s.columns === headingLookupColumns)
      ?.name
  } else {
    if (normalHeadingTypes.has(headingType)) {
      const lookupType = `${heading.headingObjectStyleType}_${headingType}`
      return setting?.paragraphStyleSettings
        ?.find(s => s.paragraphStyleSettingType === lookupType)
        ?.name
    }
  }
}

export const changeParagraphStyleMultiline = (taggedContent, paragraphStyle) => {
  const regex = /<p style="([^"]*)">(.*?)<\/p>/g
  const replaceStr = `<p style="${paragraphStyle}">$2</p>`
  return taggedContent.replaceAll(regex, replaceStr)
}

export const changeParagraphStyle = (taggedContent, paragraphStyle) => {
  const regex = /^<p style="([^"]+)">(.*)<\/p>$/g
  const replaceStr = `<p style="${paragraphStyle}">$2</p>`
  return taggedContent.replace(regex, replaceStr)
}

const FilenameTagMappings = Object.freeze({
  '[あたま]': Priority.Head,
  '[かた]': Priority.Shoulder,
  '[くび]': Priority.Neck,
  '[へそ]': Priority.Navel,
  '[右あし]': Priority.RightFoot,
  '[左あし]': Priority.LeftFoot
})

export const parseFilenameTags = (filename) => {
  const regex = /(?<tag>\[[^\]]+\])?(?<name>.*\.txt)/
  const match = regex.exec(filename)
  if (match === null) {
    return { filename }
  }
  const { groups: { name, tag } } = match
  const priority = FilenameTagMappings[tag]
  // console.log(priority)
  if (priority === undefined) {
    return { filename }
  }
  return {
    filename: name,
    tag,
    priority
  }
}

export const changeHeadingProperties = (heading, headingNumberOfOccupyingColumns, setting) => {
  const paragraphStyleName = findHeadingParagraphStyleName({ heading, headingNumberOfOccupyingColumns, setting })
  if (!paragraphStyleName) {
    console.log('paragraphStyleName not found!', heading)
    return
  }
  const newTaggedContent = changeParagraphStyle(heading.taggedContent, paragraphStyleName)
  // console.log(newTaggedContent)
  heading.taggedContent = newTaggedContent
}

export const handleHeading = ({
  headings,
  headingNumberOfOccupyingColumns,
  setting
}) => {
  const headingNumberOfOccupyingColumnsInt = parseInt(headingNumberOfOccupyingColumns)
  const numberOfNormalHeadings = headings?.filter(a => normalHeadingTypes.has(a.headingType))?.length || 0
  const headingNumberOfOccupyingRows = setting?.verticalHeadingMarginSettings
    ?.find(s => s.columns === headingNumberOfOccupyingColumnsInt && s.headings === numberOfNormalHeadings)
    ?.rows || numberOfNormalHeadings
  // console.log('handleHeading', { numberOfHeadings, headingNumberOfOccupyingColumns, headingNumberOfOccupyingRows })
  return headingNumberOfOccupyingRows
}

const headingObjectStyleTypeMapping = {
  hheadingSmall: 2,
  hheadingMedium: 3,
  hheadingLarge: 4
}

const headingObjectStyleTypeMappingInverted = Object.entries(_.invertBy(headingObjectStyleTypeMapping))
  .reduce((acc, [k, v]) => {
    acc[k] = _.snakeCase(v[0])
    return acc
  }, {})

export const changeHeadingObjectStyleType = ({
  headingObjectStyleType,
  setting
}) => {
  const targetColumns = headingObjectStyleTypeMapping[_.camelCase(headingObjectStyleType)]
  const headingNumberOfOccupyingRows = setting?.horizontalHeadingMarginSettings
    ?.find(s => s.sizeInColumns === targetColumns)
    ?.rows
  return headingNumberOfOccupyingRows
}

export const changeHeadingOrientation = ({
  article,
  newOrientation,
  basicNormalHeadings,
  setting
}) => {
  // console.log('HorizontalHeadingMarginSettingForm', layoutable.setting.horizontalHeadingMarginSettings)
  if (newOrientation === Orientation.Horizontal) {
    const { headingNumberOfOccupyingColumns } = article
    const targetSizeInColumns = (headingNumberOfOccupyingColumns <= 2)
      ? 2
      : (headingNumberOfOccupyingColumns === 3)
          ? 3
          : 4
    const headingNumberOfOccupyingRows = setting?.horizontalHeadingMarginSettings
      ?.find(s => s.sizeInColumns === targetSizeInColumns)
      ?.rows
    const headingObjectStyleType = headingObjectStyleTypeMappingInverted[targetSizeInColumns.toString()]
    // console.log({ headingObjectStyleTypeMappingInverted })
    return { headingNumberOfOccupyingColumns: 1, headingNumberOfOccupyingRows, headingObjectStyleType }
  } else {
    const { headingNumberOfOccupyingRows } = article
    // const targetSettings = setting?.horizontalHeadingMarginSettings
    const targetRows = setting?.horizontalHeadingMarginSettings?.map(s => s.rows)
    console.log({ targetRows, headingNumberOfOccupyingRows })
    const targetColumns = (headingNumberOfOccupyingRows <= targetRows[0])
      ? 2
      : (headingNumberOfOccupyingRows === targetRows[1])
          ? 3
          : 4
    const newHeadingNumberOfOccupyingRows = handleHeading({ headings: basicNormalHeadings, headingNumberOfOccupyingColumns: targetColumns, setting })
    const headingObjectStyleType = 'heading'
    return { headingNumberOfOccupyingColumns: targetColumns, headingNumberOfOccupyingRows: newHeadingNumberOfOccupyingRows, headingObjectStyleType }
  }
}

export const getFigureNumberOfOccupyingRowsFromSetting = ({
  numberOfOccupyingColumns,
  figure,
  setting
}) => {
  const { orientation } = figure
  const numberOfOccupyingRows = setting?.figureRowMarginSettings
    ?.find(s => s.orientation === orientation && s.columns === numberOfOccupyingColumns)
    ?.marginRows
  return numberOfOccupyingRows
}

export const defaultAreaSize = { width: 5, height: 1 }

const applySizeRule = ({
  layoutable, article, rule
}) => {
  if (article === undefined || rule === undefined || article.articleType === ArticleType.Template) {
    return
  }
  const {
    figures: figureRule,
    horizontalDesignedHeading, verticalDesignedHeading,
    preambleHeading: preambleHeadingRule,
    priority: priorityRule,
    ...restRule
  } = rule
  Object.assign(article, restRule)
  if (article?.priority === undefined) {
    // check if priority is already set, and only overwrite it when it's not set
    // console.log('only set priority when it is not yet set', { article, priorityRule })
    article.priority = priorityRule
  }
  const designedHeading = article?.headings?.find((h) => h.headingType === HeadingType.Designed)
  const designedHeadingRules = horizontalDesignedHeading || verticalDesignedHeading
  if (designedHeading && designedHeadingRules) {
    Object.assign(designedHeading, designedHeadingRules)
  }
  const preambleHeading = article?.headings?.find((h) => h.headingType === HeadingType.Preamble)
  if (preambleHeading && preambleHeadingRule) {
    const { numberOfOccupyingColumns } = preambleHeadingRule
    const numberOfOccupyingRows = layoutable?.setting?.verticalHeadingMarginSettings
      ?.find(s => s.columns === numberOfOccupyingColumns && s.headings === 1)
      ?.rows || numberOfOccupyingColumns
    Object.assign(preambleHeading, { numberOfOccupyingColumns, numberOfOccupyingRows })
  }
  if (article?.figures?.length > 0 && figureRule !== undefined) {
    article.figures.forEach(f => {
      if (Object.isFrozen(f)) {
        return
      }
      // use horizontal figure rule by default
      const { numberOfOccupyingColumns } = figureRule.horizontal
      const orientation = Orientation.Horizontal
      const numberOfOccupyingRows = getFigureNumberOfOccupyingRowsFromSetting({
        numberOfOccupyingColumns, figure: { orientation }, setting: layoutable.setting
      })
      Object.assign(f, { numberOfOccupyingColumns, numberOfOccupyingRows, orientation })
    })
  }
  const { preambleContent, preambleNumberOfColumns, headingNumberOfOccupyingColumns } = article
  if (!nullish(preambleContent)) {
    const { numberOfCharactersPerRow, setting } = layoutable
    const preambleAtrributes = handlePreamble({ numberOfCharactersPerRow, setting, preamble: preambleContent, preambleNumberOfColumns })
    Object.assign(article, preambleAtrributes)
  }
  article.headingNumberOfOccupyingRows = handleHeading({
    headings: article?.headings,
    headingNumberOfOccupyingColumns,
    setting: layoutable.setting
  })
}

export const createArticle = (
  article,
  layoutable,
  { templates: parsedTemplates, body: parsedBodyContent, preamble: parsedPreambleContent, headings: parsedHeadings, captions: parsedCaptions },
  applyRule
) => {
  const { numberOfCharactersPerRow, setting } = layoutable
  if (article.articleType === ArticleType.Template) {
    const { filename } = article
    // const baseFilename = filename.replace(/\.[^/.]+$/, '')
    const baseFilename = filename.substring(0, filename.length - 4)
    const pattern = `^(${baseFilename}),.*\\.(jpg|jpeg|png|eps)$`
    // console.log(pattern)
    const regex = new RegExp(pattern, 'g')
    const initialTemplateArticleArea = {
      ...defaultAreaSize,
      x: 0,
      y: 0
    }
    return {
      ...article,
      dividerLineMode: DividerLineMode.None,
      areas: [initialTemplateArticleArea],
      templateArticles: parsedTemplates?.map((t, idx) => {
        // console.log('check for figure!', { pattern, baseFilename, t, order: idx + 1 })
        if (t.toLowerCase().match(regex)) {
          return { templateArticleType: 'figure', filename: t, order: idx + 1 }
        }
        return { templateArticleType: 'text', content: t, order: idx + 1 }
      })
    }
  }
  parsedHeadings?.forEach((heading) => {
    if (heading.type === 'h_designed') {
      heading.type = HeadingType.Designed
      heading.orientation = Orientation.Horizontal
    }
  })
  const headingsCount = parsedHeadings?.length || 0
  if (headingsCount >= 8) {
    throw new Error(`「${article.filename}」に8本以上の見出しと見なされる入力がありました。(headingsCount: ${headingsCount})`)
  }
  const currentHeadingsMap = new Map(article.headings?.map(heading => [heading.content, heading]))
  const usedHeadingTypes = new Set(article.headings?.map(heading => heading.headingType))
  const currentFiguresMap = new Map(article.figures?.map(figure => [figure.caption, figure]))
  const existingHeadings = parsedHeadings?.map(h => currentHeadingsMap.get(h.content)).filter(Boolean) || []
  const nonExistingHeadings = parsedHeadings?.filter(h => !currentHeadingsMap.get(h.content))
  const headingsWithHeadingTypeParsed = nonExistingHeadings?.filter(h => h?.type !== undefined)
  const headingsWithSpecialTypeSpecified = nonExistingHeadings?.filter(h => specialHeadingTypes.has(h?.type))?.length || 0
  // exclude special types
  const nonSpecialTypeHeadingCount = headingsCount - headingsWithSpecialTypeSpecified
  const assignHeadingTypes = defaultHeadingTypeMapRules[layoutable?.layoutableType][nonSpecialTypeHeadingCount] || []
  const typeSpecifiedHeadingTypes = new Set(headingsWithHeadingTypeParsed?.map(h => h.type) || [])
  // console.log('assignHeadingTypes', assignHeadingTypes)
  const unusedHeadingTypes = assignHeadingTypes.map(_.snakeCase).filter(headingType => !usedHeadingTypes.has(headingType) && !typeSpecifiedHeadingTypes.has(headingType))
  // console.log({ unusedHeadingTypes, typeSpecifiedHeadingTypes })

  if (usedHeadingTypes.length > 0 && headingsWithHeadingTypeParsed?.some(h => usedHeadingTypes?.includes(h?.type))) {
    throw new Error('Specified Heading Type already exists.')
  }
  const newHeadingsObjectsWithType = headingsWithHeadingTypeParsed?.map(h => {
    const { type: headingType, content, ...headingProps } = h
    const heading = { headingType, content, ...headingProps }
    switch (h.type) {
      case HeadingType.Designed:
        if (h.orientation === undefined) {
          heading.orientation = Orientation.Vertical
        }
        heading.numberOfOccupyingColumns = 1
        heading.numberOfOccupyingRows = 1
        break
      case HeadingType.BottomCovering:
      case HeadingType.TopCovering:
        heading.numberOfOccupyingCharacters = 1
        heading.numberOfOccupyingRows = 1
        break
      case HeadingType.Preamble:
        // TODO: adjust this
        heading.numberOfOccupyingColumns = 1
        heading.numberOfOccupyingRows = 1
        break
    }
    return heading
  }) || []
  const headingsWithoutHeadingTypeParsed = nonExistingHeadings?.filter(h => h?.type === undefined)
  // console.log('sortedHeadings', sortedHeadings)

  const newHeadingsObjects = headingsWithoutHeadingTypeParsed?.map((h, idx) => (
    { headingType: unusedHeadingTypes[idx], content: h.content }
  )) || []
  const headingsObjects = [...existingHeadings, ...newHeadingsObjects, ...newHeadingsObjectsWithType]
  // console.log('headingsObjects', headingsObjects)
  const figuresObjects = parsedCaptions?.map((c) => (
    currentFiguresMap.get(c) || { caption: c }
  ))
  const headingNumberOfOccupyingRows = parsedHeadings?.length
  const headingNumberOfOccupyingColumns = !parsedHeadings ? null : 1
  const preambleNumberOfColumns = !parsedPreambleContent ? null : 1
  const {
    preambleNumberOfCharactersPerRow, preambleNumberOfRows, preambleMarginRows, preambleContent
  } = handlePreamble({ numberOfCharactersPerRow, setting, preamble: parsedPreambleContent, preambleNumberOfColumns })

  const bodyNumberOfRows = !parsedBodyContent ? null : getNumberOfRows(numberOfCharactersPerRow, parsedBodyContent)
  const newArticle = {
    ...article,
    ...{
      // order,
      headingNumberOfOccupyingColumns,
      headingNumberOfOccupyingRows,
      bodyNumberOfRows,
      preambleNumberOfCharactersPerRow,
      preambleNumberOfColumns,
      preambleNumberOfRows,
      preambleMarginRows,
      preambleContent,
      bodyContent: parsedBodyContent,
      headings: headingsObjects,
      figures: figuresObjects
    }
  }
  if (applyRule) {
    const defaultArticleRules = getDefaultArticleRules(layoutable, layoutable?.articles)
    const rule = defaultArticleRules?.find(r => r.priority === newArticle.priority)
    if (rule) {
      console.log('apply rule', rule)
      applySizeRule({ layoutable, article: newArticle, rule })
    }
  }
  return newArticle
}

export const changeArticlePriority = ({ layoutable, article, priority }) => {
  const defaultArticleRules = getDefaultArticleRules(layoutable, layoutable?.articles)
  const rule = defaultArticleRules?.find(r => r.priority === priority)
  if (rule) {
    console.log('apply rule', rule)
    applySizeRule({ layoutable, article, rule })
  }
}

export const handleBinaryFile = async (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new window.FileReader()
    fileReader.onloadend = () => {
      resolve({
        name: file.name.normalize('NFC'),
        blob: new window.Blob([fileReader.result], { type: 'application/octet-stream' })
      })
    }
    fileReader.onerror = reject
    fileReader.readAsArrayBuffer(file)
  })
}

export const handleTextFile = async (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new window.FileReader()
    fileReader.onloadend = () => {
      resolve({
        name: file.name.normalize('NFC'),
        content: fileReader.result.replace(/\r\n/g, '\n')
      })
    }
    fileReader.onerror = reject
    fileReader.readAsText(file, 'utf8')
  })
}

export const parseArticleFile = ({ name, content }) => {
  try {
    // console.log(`'${content}'`)
    const { headings, body } = jiji.parse(content)
    return { headings, body }
  } catch (e) {
    if (e instanceof jiji.SyntaxError) {
      // console.log(`${name} was not not a jiji content`)
      try {
        return parse(content)
      } catch (e) {
        if (e instanceof SyntaxError) {
          window.alert(`ファイル「${name}」のフォーマットが正しくありません。`)
          throw e
        } else {
          window.alert(`ファイル「${name}」の処理中にエラーが発生しました。\n${e}`)
          throw e
        }
      }
    } else {
      window.alert(`ファイル「${name}」の処理中にエラーが発生しました。\n${e}`)
      throw e
    }
  }
}

const getAuxiliaryLineMappings = (layoutable) => {
  return layoutable?.setting?.auxiliaryLineSettings?.reduce((acc, item) => {
    const { id, priority, ...newItem } = item
    acc[priority] = newItem
    return acc
  }, {})
}

export const getDefaultAuxiliaryLine = ({
  layoutable,
  priority
}) => {
  const defaultAuxiliaryLine = {
    startColumn: 0,
    startRow: 0,
    length: 1
  }
  // console.log(page.setting.auxiliaryLineSettings)
  const auxiliaryLineMappings = getAuxiliaryLineMappings(layoutable)
  return auxiliaryLineMappings[priority] || defaultAuxiliaryLine
}

const getDefaultArticleRules = (layoutable, parsedArticles) => {
  const firstArticleDesignedHeadingOrientation = parsedArticles?.[0]?.headings?.find((h) => h.headingType === HeadingType.Designed)?.orientation
  let pattern
  switch (firstArticleDesignedHeadingOrientation) {
    case Orientation.Horizontal:
      pattern = 'pattern1'
      break
    case Orientation.Vertical:
      pattern = 'pattern2'
      break
    default:
      pattern = 'pattern3'
      break
  }
  const auxiliaryLineMappings = getAuxiliaryLineMappings(layoutable)
  const defaultArticleRules = layoutable?.defaultArticleRules?.[pattern]
  return defaultArticleRules?.map((r) => {
    const { useAuxiliaryLine, ...restRule } = r
    if (useAuxiliaryLine) {
      restRule.auxiliaryLine = auxiliaryLineMappings[r.priority]
    }
    return restRule
  })
}

const ascCompare = (a, b) => {
  return (a === b) ? 0 : (a < b) ? -1 : 1
}

const dscCompare = (a, b) => {
  return (a === b) ? 0 : (a > b) ? -1 : 1
}

// Compare articles by bodyNumberOfRows in descending order
const articleBodyCompare = (a, b) => {
  const aOrder = PrioritySortOrder[a?.priority] || PrioritySortOrderUndefined
  const bOrder = PrioritySortOrder[b?.priority] || PrioritySortOrderUndefined
  const aBodyRows = a?.bodyNumberOfRows || -1
  const bBodyRows = b?.bodyNumberOfRows || -1
  return ascCompare(aOrder, bOrder) || dscCompare(aBodyRows, bBodyRows) || a?.filename?.localeCompare(b.filename)
}

export const readAllFiles = async ({
  layoutable,
  holderArticle,
  allFiles
}) => {
  const textFiles = allFiles.filter(file => file.name.endsWith('.txt'))
  const otherFiles = allFiles.filter(file => !file.name.endsWith('.txt'))
  const loadedTextFiles = await Promise.all(textFiles.map(async (file) => {
    const handled = await handleTextFile(file)
    return handled
  }))
  const loadedBinaryFiles = await Promise.all(otherFiles.map(async (file) => {
    const handled = await handleBinaryFile(file)
    return handled
  }))
  const parsedArticles = loadedTextFiles.map(({ name, content }) => {
    const parsedResult = parseArticleFile({ name, content })
    const articleType = ('templates' in parsedResult) ? ArticleType.Template : ArticleType.Flow
    const columnSpaceExistence = articleType === ArticleType.Flow
    const { filename, priority } = parseFilenameTags(name)
    return createArticle({ filename, priority, articleType, columnSpaceExistence }, layoutable, parsedResult, false)
  })
  // console.log('parsedArticles', parsedArticles)
  // 小組inddファイル
  const composedArticles = loadedBinaryFiles.filter(({ name }) => name.endsWith('.indd'))
  const imageFiles = loadedBinaryFiles.filter(({ name }) => !name.endsWith('.indd'))
  const nonTextArticles = composedArticles.map(({ name }) => (
    { filename: name, articleType: 'area', areas: [defaultAreaSize] }
  ))
  // console.log('debug', { articles: layoutable.articles, holderArticle })
  if (layoutable.articles?.length === 0 && (!holderArticle || holderArticle.figures.length === 0)) {
    // console.log('new upload')
    if (imageFiles.length > 0) {
      const figures = imageFiles.map(({ name }) => ({ filename: name }))
      if (holderArticle?.figures.length === 0) {
        console.log('populate figures of existing holder article with new figures', figures)
      }
      const newHolderArticle = holderArticle === undefined
        ? { articleType: 'holder', figures }
        : { ...holderArticle, figures }
      nonTextArticles.push(newHolderArticle)
    }
    const sortedArticles = parsedArticles?.sort(articleBodyCompare)
    // console.log('sortedArticles before push', sortedArticles)
    sortedArticles.push(...nonTextArticles)
    const nonHolderArticles = sortedArticles?.filter(a => a.articleType !== 'holder')
    const defaultSizeRules = getDefaultArticleRules(layoutable, parsedArticles)
    // console.log('defaultSizeRules', defaultSizeRules)
    _.zip(nonHolderArticles, defaultSizeRules).forEach(([article, rule]) => {
      applySizeRule({ layoutable, article, rule })
    })
    // console.log('nonHolderArticles', nonHolderArticles)
    // console.log('sortedArticles', sortedArticles)
    const otherArticles = sortedArticles?.filter((a) => a.articleType !== 'holder' && (!a.priority || a.priority === Priority.Other))
    // console.log('otherArticles', otherArticles)
    otherArticles.forEach((article, idx) => {
      article.priority = Priority.Other
      article.order = idx
    })
    return {
      articles: sortedArticles,
      files: loadedBinaryFiles
    }
  } else {
    // console.log('layoutable.articles', layoutable.articles)
    // console.log('holderArticle', holderArticle)
    if (imageFiles.length > 0) {
      const figures = imageFiles.map(({ name }) => ({ filename: name }))
      const newHolderArticle = holderArticle
        ? {
            id: holderArticle.id,
            articleType: 'holder',
            figures: [...holderArticle.figures]
          }
        : {
            articleType: 'holder',
            figures: []
          }
      newHolderArticle.figures.push(...figures)
      // console.log('after concat', newHolderArticle)
      nonTextArticles.push(newHolderArticle)
    }
    const orderOffset = (layoutable.articles?.filter((a) => a.priority === Priority.Other)
      ?.map((a) => a.order)
      ?.sort((a, b) => (b - a))?.[0] || -1) + 1
    const sortedArticles = parsedArticles?.sort(articleBodyCompare)
    sortedArticles.push(...nonTextArticles)
    sortedArticles.forEach((article, idx) => {
      article.priority = Priority.Other
      article.order = idx + orderOffset
    })
    return {
      articles: sortedArticles,
      files: loadedBinaryFiles
    }
  }
}

const areaSpecifiedArticleTypes = new Set([ArticleType.Area, ArticleType.Template])

export const isAreaArticleType = (articleType) => {
  return areaSpecifiedArticleTypes.has(articleType)
}

const sizeSpecifiedArticleTypes = new Set([ArticleType.Fold, ArticleType.Anchor])

export const isSizedArticleType = (articleType) => {
  return sizeSpecifiedArticleTypes.has(articleType)
}

const topPositionPriorities = new Set([Priority.Head, Priority.Shoulder, Priority.Neck])

export const isTopPositionPriority = (priority) => {
  return topPositionPriorities.has(priority)
}

export const isPositionRequired = (articleType) => {
  return articleType === ArticleType.Template
}

export const nullish = (val) => {
  switch (val) {
    case '':
    case null:
    case undefined:
      return true
    default:
      return false
  }
}

export const nullishValueToValue = (val, emptyValue = '') => {
  return !nullish(val) ? parseInt(val) + 1 : emptyValue
}

export const nullishValueToInternalValue = (val, emptyValue = '') => {
  return !nullish(val) ? parseInt(val) - 1 : emptyValue
}

export const toRightPosValue = (numberOfRows, leftPosValue) => {
  // console.log('toRightPosValue', { leftPosValue })
  if (nullish(leftPosValue)) {
    return leftPosValue
  }
  const rightPosValue = numberOfRows - leftPosValue - 1
  // console.log('toRightPosValue', { leftPosValue, rightPosValue, numberOfRows })
  return rightPosValue
}

export const toPosDisplayValue = (layoutable, area) => {
  const rightPosValue = nullishValueToValue(area?.x)
  if (nullish(rightPosValue)) {
    return rightPosValue
  }
  const { numberOfRows } = layoutable
  const displayValue = area?.fromLeft ? (numberOfRows - rightPosValue + 1) : rightPosValue
  // console.log('toPosDisplayValue', { fromLeft, values, displayValue, rightPosValue })
  return displayValue
}

const allowedArticleTypesPerPriority = {
  // anchor: 左寄せ    # 押っ付け
  // fold:   右寄せ    # たたみ
  [Priority.Head]: [ArticleType.Flow, ArticleType.Fold, ArticleType.Area],
  [Priority.Shoulder]: [ArticleType.Flow, ArticleType.Anchor, ArticleType.Area],
  [Priority.Neck]: [ArticleType.Flow, ArticleType.Area],
  [Priority.Navel]: [ArticleType.Flow, ArticleType.Area],
  [Priority.RightFoot]: [ArticleType.Flow, ArticleType.Fold, ArticleType.Area],
  [Priority.LeftFoot]: [ArticleType.Flow, ArticleType.Anchor, ArticleType.Area]
}

export const isDisabledArticleType = (priority, articleType) => {
  if (priority === Priority.Other) {
    return false
  }
  return !(allowedArticleTypesPerPriority[priority]?.includes(articleType))
}

export const coerceArticleTypeOnPriorityChange = (priority, articleType) => {
  if (isAreaArticleType(articleType) || priority === Priority.Other) {
    return articleType
  }
  if (!isDisabledArticleType(priority, articleType)) {
    return articleType
  }
  return ArticleType.Flow
}

export const getDefaultDividerLineMode = (articleType) => {
  if (articleType && isAreaArticleType(articleType)) {
    return DividerLineMode.None
  }
  return DividerLineMode.Both
}

const layoutViewableStatus = new Set([Status.LayoutEngineComplete, Status.LayoutComplete])
const layoutEditableStatus = new Set([Status.LayoutInProgress, Status.LayoutEngineComplete, Status.LayoutEngineError])
const layoutEngineExecutableStatus = new Set([Status.LayoutInProgress, Status.LayoutEngineError])
const layoutEngineInProgressStatus = new Set([Status.LayoutEngineWaiting, Status.LayoutEngineExecuting])

export const isLayoutViewableStatus = (status) => {
  return layoutViewableStatus.has(status)
}

export const isLayoutEditableStatus = (status) => {
  return layoutEditableStatus.has(status)
}

export const isLayoutEngineExecutableStatus = (status) => {
  return layoutEngineExecutableStatus.has(status)
}

export const isLayoutEngineInProgressStatus = (status) => {
  return layoutEngineInProgressStatus.has(status)
}
